import React, { useRef, useEffect } from 'react';
import Parallax from 'parallax-js';
import { graphql, useStaticQuery } from 'gatsby';
import { isBrowser } from 'react-device-detect';
import { getImage } from 'gatsby-plugin-image';
import { bbAnimation } from 'theme/Animations';
import withLocale from 'hoc/withLocale';
import CreamIcon from 'components/BB/CreamIcon';
import FilterIcon from 'components/BB/FilterIcon';
import MakeupIcon from 'components/BB/MakeupIcon';
import parse from 'html-react-parser';

import {
  StyledSection,
  StyledContent,
  StyledTitle,
  StyledSubtitle,
  StyledProductWrapper,
  StyledFunctionColFirst,
  StyledProductsCol,
  StyledFunctionColSecond,
  StyledProductInnerWrapper,
  StyledProduct,
  StyledPalmBgDesktop,
  StyledPalmBgMobile,
  StyledSectionBgWrapper,
  StyledProductBgWrapper,
  StyledProductBg,
  StyledProductsColWrapper,
} from './BBStyles';

const uuidv4 = require('uuid/v4');

const BB = ({ data }) => {
  const localData = useStaticQuery(graphql`
    {
      sectionBgDesktop: file(
        relativeDirectory: { eq: "bb" }
        name: { eq: "section-bg-palm-desktop-new" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      sectionBgMobile: file(
        relativeDirectory: { eq: "bb" }
        name: { eq: "section-bg-palm-mobile-new" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      productsBg: file(
        relativeDirectory: { eq: "bb" }
        name: { eq: "product-bg-palm-new" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  const renderImages = images => {
    const limitedData = images.slice(0, 5);
    const formatedData = [];

    limitedData.forEach((item, index) => {
      const newItem = {
        ...item,
      };

      if (index === 0) {
        newItem.row = 'first';
        newItem.dataDepth = '0.1';
      } else if (index === 1 || index === 2) {
        newItem.row = 'middle';
        newItem.dataDepth = '0.2';
      } else if (index === 3 || index === 4) {
        newItem.row = 'last';
        newItem.dataDepth = '0.3';
      }

      formatedData.push(newItem);
    });

    return formatedData.map(item => {
      const gatsbyImage = getImage(item.localFile);
      const alt = item.alternativeText ?? 'bb_cream';
      return (
        <StyledProductInnerWrapper
          key={uuidv4()}
          data-depth={item.dataDepth}
          row={item.row}
        >
          <StyledProduct image={gatsbyImage} alt={alt} />
        </StyledProductInnerWrapper>
      );
    });
  };

  const scene = useRef(null);
  let container = useRef(null);
  let title = useRef(null);
  let subtitle = useRef(null);
  let function1 = useRef(null);
  let function2 = useRef(null);
  let function3 = useRef(null);
  let sectionBg = useRef(null);
  let productsBg = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      bbAnimation(
        container,
        title,
        subtitle,
        function1,
        function2,
        function3,
        sectionBg,
        productsBg
      );
      const parallaxInstance = new Parallax(scene.current);
      parallaxInstance.enable();

      return () => parallaxInstance.disable();
    }
  }, []);

  return (
    <StyledSection
      ref={el => {
        container = el;
      }}
    >
      <StyledContent>
        <StyledTitle
          ref={el => {
            title = el;
          }}
        >
          {parse(data.title)}
        </StyledTitle>
        <StyledSubtitle
          ref={el => {
            subtitle = el;
          }}
        >
          {parse(data.text)}
        </StyledSubtitle>
      </StyledContent>

      <StyledProductWrapper>
        <StyledFunctionColFirst>
          <CreamIcon
            ref={el => {
              function1 = el;
            }}
            text={data.list_item_1}
          />
        </StyledFunctionColFirst>

        <StyledProductsColWrapper>
          <StyledProductBgWrapper
            ref={el => {
              productsBg = el;
            }}
          >
            <StyledProductBg
              fluid={localData.productsBg.childImageSharp.fluid}
            />
          </StyledProductBgWrapper>

          <StyledProductsCol ref={scene}>
            {renderImages(data.images)}
          </StyledProductsCol>
        </StyledProductsColWrapper>

        <StyledFunctionColSecond>
          <FilterIcon
            ref={el => {
              function2 = el;
            }}
            text={data.list_item_2}
          />
          <MakeupIcon
            ref={el => {
              function3 = el;
            }}
            text={data.list_item_3}
          />
        </StyledFunctionColSecond>
      </StyledProductWrapper>

      <StyledSectionBgWrapper
        ref={el => {
          sectionBg = el;
        }}
      >
        <StyledPalmBgDesktop
          fluid={localData.sectionBgDesktop.childImageSharp.fluid}
        />
        <StyledPalmBgMobile
          fluid={localData.sectionBgMobile.childImageSharp.fluid}
        />
      </StyledSectionBgWrapper>
    </StyledSection>
  );
};

export default withLocale(BB);
