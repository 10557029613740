import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

export const StyledProduct = styled('div')`
  position: relative;
  z-index: 1;
  max-width: 320px;
  &:first-child {
    display: none;
  }
  &:nth-child(2) {
    align-self: flex-start;
    transform: translate(-30%, 20%);
    order: 1;
  }
  &:nth-child(3) {
    align-self: flex-end;
    transform: translate(36%, 8%) rotate(-17deg);
    order: 3;
  }
  &:nth-child(4) {
    display: none;
  }
  ${({ theme }) => theme.mq.sm} {
    max-width: 380px;
  }
  ${({ theme }) => theme.mq.smm} {
    max-width: 500px;
  }
  ${({ theme }) => theme.mq.md} {
    max-width: 550px;
  }
  ${({ theme }) => theme.mq.lg} {
    max-width: 440px;
    &:first-child {
      display: block;
      align-self: flex-start;
      transform: translate(-23%, 10%);
      order: 1;
    }
    &:nth-child(2) {
      align-self: flex-start;
      transform: translate(30%, 10%);
      order: 2;
    }
    &:nth-child(3) {
      align-self: flex-end;
      transform: translate(-36%, -5%) scaleX(-1) rotate(-17deg);
      order: 4;
    }
    &:nth-child(4) {
      display: block;
      align-self: flex-end;
      transform: translate(36%, 8%);
      order: 5;
    }
  }
  ${({ theme }) => theme.mq.xl} {
    &:first-child {
      transform: translate(-23%, 15%);
    }
    &:nth-child(2) {
      order: 4;
      transform: translate(-23%, 10%);
    }
    &:nth-child(3) {
      order: 5;
      transform: translate(36%, 8%) rotate(-17deg);
    }
    &:nth-child(4) {
      order: 2;
    }
  }
  ${({ theme }) => theme.mq.xxxl} {
    max-width: 550px;
    &:first-child {
      transform: translate(10%, 23%);
    }
    &:nth-child(2) {
      transform: translate(10%, 10%);
    }
    &:nth-child(3) {
      transform: translate(-10%, 10%) rotate(-17deg);
    }
    &:nth-child(4) {
      transform: translate(-10%, 15%);
    }
  }
`;

export const StyledWrapper = styled('div')`
  position: relative;
  z-index: 1;
  max-width: 100%;
`;

export const StyledInnerWrapper = styled('div')`
  position: relative;
  z-index: 1;
  width: 100%;
`;

export const StyledImgWrapper = styled('div')`
  max-width: 100%;
  max-height: 100%;
`;

export const StyledImg = styled(GatsbyImage)``;
