import styled from "styled-components";

export const StyledWrapper = styled('div')`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    svg{
        position: absolute;
        &:first-child{
            width: 68%;
            top: -13% !important;
            left: 0% !important;
            ${({ theme }) => theme.mq.md} {
                top: 6% !important;
                left: 0% !important;
            }
            ${({ theme }) => theme.mq.xl} {
                top: 15% !important;
            }
        }
        &:nth-child(2){
            display: none;
            ${({ theme }) => theme.mq.md} {
                display: block;
                left: 55% !important;
                top: 75% !important;
            }
            ${({ theme }) => theme.mq.xl} {
                display: block;
                top: 100% !important;
                left: 65% !important;
            }
            ${({ theme }) => theme.mq.xxxl} {
                top: 80% !important;
            }
        }
        &:nth-child(3){
            display: none;
            ${({ theme }) => theme.mq.md} {
                display: block;
                left: -20% !important;
                top: 30% !important;
            }
            ${({ theme }) => theme.mq.xl} {
                display: block;
                top: 34% !important;
                left: -16% !important;
            }
        }
    }
`;
