import styled from "styled-components";

export const StyledBg = styled('div')`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
    img {
        object-fit: contain;
        position: absolute;
        &:nth-of-type(1) {
            z-index: 1;
            top: 50%;
            left: 50%;
            width: 60%;
            transform: translate(-50%, -50%);
        }
        &:nth-of-type(2) {
            top: 1% !important;
            left: 6% !important;
            width: 47%;
            z-index: 2;
            ${({ theme }) => theme.mq.smm} {
                top: 13% !important;
                left: 9% !important;
            }
            ${({ theme }) => theme.mq.md} {
                top: 10% !important;
                left: 7% !important;
            }
            ${({ theme }) => theme.mq.xxl} {
                top: 6% !important;
                left: 5% !important;
            } 
            ${({ theme }) => theme.mq.xxxl} {
                top: 11% !important;
                left: 8% !important;
            } 
        }
        &:nth-of-type(3) {
            top: 12% !important;
            left: 41% !important;
            width: 56%;
            z-index: 2;
            ${({ theme }) => theme.mq.smm} {
                top: 22% !important;
                left: 40% !important;
            }
            ${({ theme }) => theme.mq.md} {
                top: 19% !important;
            } 
            ${({ theme }) => theme.mq.xxl} {
                top: 15% !important;
            } 
            ${({ theme }) => theme.mq.xxxl} {
                top: 19% !important;
            } 
        }
        &:nth-of-type(4) {
            top: 79% !important;
            left: 5% !important;
            width: 52%;
            z-index: 2;
            ${({ theme }) => theme.mq.smm} {
                top: 72% !important;
                left: 6% !important;
            }
            ${({ theme }) => theme.mq.md} {
                top: 74% !important;
            }
            ${({ theme }) => theme.mq.xxl} {
                top: 77% !important;
            } 
            ${({ theme }) => theme.mq.xxxl} {
                top: 74% !important;
            } 
        }
    }
`
