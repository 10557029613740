import styled from "styled-components";

export const StyledWrapper = styled('div')`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    svg{
        position: absolute;
        &:first-child{
            width: 20%;
            top: 10% !important;
            left: 20% !important;
            ${({ theme }) => theme.mq.sm} {
                top: 17% !important;
            }
            ${({ theme }) => theme.mq.smm} {
                top: 25% !important;
            }
            ${({ theme }) => theme.mq.xl} {
                top: 21% !important;
                left: 20% !important;
            }
        }
    }
`;
