import styled from "styled-components";

export const StyledCircle = styled('svg')`
    display: none;
    ${({ theme }) => theme.mq.lg} {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(20%);
        /* width: 10vw; */
        z-index: 0;
        max-width: 559px;
    }
`;
