import styled from 'styled-components';

export const StyledSuggestions = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.yellow};
  position: relative;
  ${({ theme }) => theme.mq.xl} {
    justify-items: center;
    align-items: flex-start;
    grid-template-columns: 1fr 49vw;
  }
`;

export const StyledImagesWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const StyledImageWrapper = styled('div')`
  cursor: pointer;
  width: 50vw;
  height: 50vw;
  position: relative;
  ${({ theme }) => theme.mq.md} {
    width: calc(100vw / 3);
    height: calc(100vw / 3);
  }
  ${({ theme }) => theme.mq.xl} {
    width: 50%;
    height: 20vw;
  }
`;

export const StyledImage = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-image: url(${({ imageSrc }) => imageSrc});
  transition: opacity 1s;
  &:first-child {
    z-index: 1;
    opacity: 1;
  }
  &:last-child {
    z-index: 0;
    opacity: 0;
  }
`;

export const StyledContentWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  padding: 8rem 2.5rem 5rem;
  max-width: 600px;
  margin: 0 auto;
  ${({ theme }) => theme.mq.lg} {
    max-width: 950px;
  }
  ${({ theme }) => theme.mq.xl} {
    margin: 0;
    padding: 12rem 4rem 4rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    padding: 20rem 6rem 4rem;
  }
`;

export const StyledEllipse = styled('img')`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transform: translateY(-30%);
  width: 150px;
  ${({ theme }) => theme.mq.md} {
    transform: translate(-30%, -30%);
  }
  ${({ theme }) => theme.mq.xl} {
    width: 200px;
  }
  ${({ theme }) => theme.mq.xxxl} {
    width: 308px;
    transform: translate(-40%, -30%);
  }
`;

export const StyledHeadingWrapper = styled('div')`
  position: relative;
  margin-bottom: 3rem;
  ${({ theme }) => theme.mq.xl} {
    margin-bottom: 5rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    margin-bottom: 7rem;
  }
`;

export const StyledHeading = styled('div')`
  color: ${({ theme }) => theme.colors.pink};
  font-size: 2.4rem;
  line-height: 3.6rem;
  position: relative;
  font-weight: 600;
  ${({ theme }) => theme.mq.lg} {
    font-size: 3rem;
    line-height: 4.2rem;
  }
  ${({ theme }) => theme.mq.xl} {
    font-weight: 700;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 4rem;
    line-height: 5.6rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    font-size: 4.8rem;
    line-height: 7.2rem;
  }
`;

export const StyledText = styled('div')`
  color: ${({ theme }) => theme.colors.pink};
  font-size: 1.8rem;
  line-height: 3.6rem;
  margin: 0 0 7rem 0;
  position: relative;
  ${({ theme }) => theme.mq.lg} {
    font-size: 2rem;
    line-height: 4rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 2.8rem;
    line-height: 5.4rem;
  }
`;

export const StyledButton = styled('button')`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.pink};
  text-align: center;
  position: relative;
  border-radius: 4px;
  padding: 18px 50px;
  font-size: 1.8rem;
  line-height: 1.8rem;
  font-weight: 600;
  transition: 0.3s;
  width: 100%;
  &:hover {
    background-color: ${({ theme }) => theme.colors.purpleDarkest};
  }
  ${({ theme }) => theme.mq.xl} {
    max-width: 288px;
  }
`;
