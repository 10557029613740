import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

export const StyledCare = styled('div')`
  display: grid;
  grid-template-columns: 100vw;
  grid-template-rows: 90vw 1fr;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #553999 -100%, #432883 100%);
  position: relative;
  ${({ theme }) => theme.mq.md} {
    grid-template-rows: 70vw 1fr;
  }
  ${({ theme }) => theme.mq.xl} {
    min-height: 100vh;
    grid-template-columns: 51% 49%;
    grid-template-rows: 1fr;
  }
`;

export const StyledSwiper = styled('div')`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 9;
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 4% !important;
  }
  .swiper-pagination {
    display: block !important;
  }
`;

export const StyledImg = styled(BackgroundImage)`
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
`;

export const StyledContentWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 4rem 2.5rem 5rem;
  max-width: 600px;
  margin: 0 auto;
  ${({ theme }) => theme.mq.lg} {
    max-width: 950px;
  }
  ${({ theme }) => theme.mq.xl} {
    margin: 0;
    padding: 4rem;
  }
  ${({ theme }) => theme.mq.xl} {
    margin: 0;
    padding: 4rem 6rem;
  }
`;

export const StyledHeading = styled('div')`
  color: ${({ theme }) => theme.colors.white};
  font-size: 3.6rem;
  line-height: 5.4rem;
  ${({ theme }) => theme.mq.xl} {
    font-size: 5.2rem;
    line-height: 7.4rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    font-size: 7.2rem;
    line-height: 8.4rem;
  }
`;

export const StyledText = styled('div')`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.8rem;
  line-height: 3.6rem;
  margin-bottom: 5rem;
  ${({ theme }) => theme.mq.xl} {
    font-size: 2.4rem;
    line-height: 4.2rem;
    margin-bottom: 6rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    font-size: 3.6rem;
    line-height: 5.4rem;
    margin-bottom: 8rem;
  }
`;

export const StyledItems = styled('ul')`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

export const StyledItem = styled('li')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
  ${({ theme }) => theme.mq.xl} {
    margin-bottom: 3rem;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledIconBorder = styled('div')`
  height: 55px;
  width: 55px;
  padding: 5px;
  border-radius: 100%;
  flex-shrink: 0;
  background: linear-gradient(
    146.43deg,
    #643ac2 -4.65%,
    rgba(100, 58, 194, 0) 111.83%
  );
  ${({ theme }) => theme.mq.smm} {
    height: 68px;
    width: 68px;
  }
  ${({ theme }) => theme.mq.xl} {
    height: 78px;
    width: 78px;
  }
  ${({ theme }) => theme.mq.xxxl} {
    height: 84px;
    width: 84px;
  }
`;

export const StyledIcon = styled('div')`
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 10px;
  background: linear-gradient(180deg, #553999 -100%, #432883 100%);
  svg {
    fill: ${({ theme }) => theme.colors.white};
    max-width: 100%;
  }
`;

export const StyledIconText = styled('span')`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin-left: 2rem;
  ${({ theme }) => theme.mq.xl} {
    font-size: 2rem;
    line-height: 3rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    font-size: 2.4rem;
    line-height: 2.9rem;
    margin-left: 4rem;
  }
`;
