import React, { useRef, useEffect } from 'react';
import Parallax from 'parallax-js';
import { isBrowser } from 'react-device-detect';

import { StyledWrapper } from './BackgroundShapes1Styles';

const BackgroundShapes1 = () => {
  const scene = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      const parallaxInstance = new Parallax(scene.current);
      parallaxInstance.enable();

      return () => parallaxInstance.disable();
    }
  }, []);

  return (
    <StyledWrapper ref={scene}>
      <svg
        data-depth={(Math.random() - 0.9) / 2}
        width="235"
        height="224"
        viewBox="0 0 235 224"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M235 94C235 145.915 192.915 224 141 224C89.0852 224 0.5 151.915 0.5 100C0.5 48.0852 89.0852 0 141 0C192.915 0 235 42.0852 235 94Z"
          fill="#F99A9B"
        />
      </svg>

      <svg
        data-depth={(Math.random() - 0.9) / 2}
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="14" cy="14" r="14" fill="#F99A9B" />
      </svg>

      <svg
        data-depth={(Math.random() - 0.9) / 2}
        width="52"
        height="52"
        viewBox="0 0 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="26" cy="26" r="26" fill="#F99A9B" />
      </svg>
    </StyledWrapper>
  );
};

export default BackgroundShapes1;
