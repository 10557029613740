import React, { useEffect, useRef } from 'react';
import { isBrowser } from 'react-device-detect';

import parse from 'html-react-parser';
import { cleanAnimation } from 'theme/Animations';
import { getImage } from 'gatsby-plugin-image';

import {
  StyledClean,
  StyledContent,
  StyledTitle,
  StyledSubtitle,
  StyledProducts,
  StyledProduct,
  StyledImageWrapper,
  StyledImage,
} from './CleanStyles';

const Clean = ({ data, currentLocale }) => {
  let container = useRef(null);
  let title = useRef(null);
  let subtitle = useRef(null);
  const items = useRef([]);

  useEffect(() => {
    if (isBrowser) {
      cleanAnimation(container, items, title, subtitle);
    }
  }, []);

  return (
    <StyledClean
      ref={el => {
        container = el;
      }}
    >
      <StyledProducts>
        {data.images.map((image, index) => {
          const background = data.backgrounds[index];

          return (
            <StyledProduct
              Tag="div"
              fluid={background.localFile.childImageSharp.fluid}
              key={index}
              alt={background.alternativeText}
            >
              <StyledImageWrapper
                ref={el => {
                  items.current[index] = el;
                }}
                onClick={() => {
                  if (image.caption) {
                    const links = image.caption.split('|');
                    window.open(
                      currentLocale === 'en' && links[1] ? links[1] : links[0],
                      '_blank'
                    );
                  }
                }}
              >
                <StyledImage
                  title={image.alternativeText}
                  alt={image.alternativeText}
                  image={getImage(image.localFile)}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </StyledImageWrapper>
            </StyledProduct>
          );
        })}
      </StyledProducts>
      <StyledContent>
        <StyledTitle
          ref={el => {
            title = el;
          }}
        >
          {parse(data.title)}
        </StyledTitle>
        <StyledSubtitle
          ref={el => {
            subtitle = el;
          }}
        >
          {parse(data.text)}
        </StyledSubtitle>
      </StyledContent>
    </StyledClean>
  );
};

export default Clean;
