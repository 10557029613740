import React, { useRef, useEffect } from 'react';
import Parallax from 'parallax-js';
import { isBrowser } from 'react-device-detect';

import { StyledWrapper } from './BackgroundShapes2Styles';

const BackgroundShapes2 = () => {
  const scene = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      const parallaxInstance = new Parallax(scene.current);
      parallaxInstance.enable();

      return () => parallaxInstance.disable();
    }
  }, []);

  return (
    <StyledWrapper ref={scene}>
      <svg
        data-depth={(Math.random() - 0.9) / 2}
        width="188"
        height="188"
        viewBox="0 0 188 188"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="94" cy="94" r="94" fill="#F99A9B" />
      </svg>
    </StyledWrapper>
  );
};

export default BackgroundShapes2;
