import React, { useRef, useEffect } from 'react';
import Parallax from 'parallax-js';
import { isBrowser } from 'react-device-detect';

import image1 from './step1/ritual_face_1.svg';
import image2 from './step1/ritual_face_2.svg';
import image3 from './step1/ritual_face_3.svg';
import image4 from './step1/ritual_face_4.svg';
import image5 from './step1/ritual_face_5.svg';
import image6 from './step1/ritual_face_6.svg';

import { StyledFace } from './Step1Styles';

const layers = [
  {
    id: 1,
    name: 'face',
    image: image1,
    dataDepth: '0.2',
  },
  {
    id: 2,
    name: 'circle-small',
    image: image2,
    dataDepth: '-0.8',
  },
  {
    id: 3,
    name: 'plant',
    image: image3,
    dataDepth: '0.5',
  },
  {
    id: 4,
    name: 'plant2',
    image: image4,
    dataDepth: '1.2',
  },
  {
    id: 5,
    name: 'circle-big',
    image: image5,
    dataDepth: '0.8',
  },
  {
    id: 6,
    name: 'circle-medium',
    image: image6,
    dataDepth: '-0.3',
  },
];

const Step1 = () => {
  const scene = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      const parallaxInstance = new Parallax(scene.current);
      parallaxInstance.enable();

      return () => parallaxInstance.disable();
    }
  }, []);

  return (
    <StyledFace ref={scene}>
      {layers.map(layer => (
        <img
          key={layer.id}
          data-depth={layer.dataDepth}
          src={layer.image}
          alt={layer.name}
        />
      ))}
    </StyledFace>
  );
};

export default Step1;
