import styled from "styled-components";

export const StyledFace = styled('div')`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 270px;
    height: 300px;
    ${({ theme }) => theme.mq.lg} {
        width: 350px;
        height: 350px;
    }
    img {
        object-fit: contain;
        position: absolute;
        &:nth-of-type(1) {
            z-index: 5;
            top: 50%;
            left: 50%;
            width: 180px;
            height: 230px;
            transform: translate(-50%, -50%);
            ${({ theme }) => theme.mq.lg} {
                width: 210px;
                height: 260px;
            }
            ${({ theme }) => theme.mq.xxxl} {
                width: 260px;
                height: 310px;
            }
        }
        &:nth-of-type(2) {
            top: 7% !important;
            left: 53% !important;
            width: 26px;
            height: 26px;
            ${({ theme }) => theme.mq.lg} {
                width: 35px;
                height: 35px;
            }
            ${({ theme }) => theme.mq.xxxl} {
                width: 46px;
                height: 46px;
                top: 0 !important;
            }
        }
        &:nth-of-type(3) {
            top: 19% !important;
            left: 71% !important;
            width: 42px;
            height: 46px;
            ${({ theme }) => theme.mq.lg} {
                width: 58px;
                height: 58px;
            }
            ${({ theme }) => theme.mq.xxxl} {
                width: 78px;
                height: 75px;
            }
        }
        &:nth-of-type(4) {
            top: 65% !important;
            left: -9% !important;
            width: 56px;
            height: 76px;
            ${({ theme }) => theme.mq.lg} {
                left: -5% !important;
                width: 66px;
                height: 91px;
            }
            ${({ theme }) => theme.mq.xxxl} {
                width: 75px;
                height: 113px;
            }
        }
        &:nth-of-type(5) {
            top: 36% !important;
            left: 3% !important;
            width: 140px;
            height: 140px;
            ${({ theme }) => theme.mq.lg} {
                top: 38% !important;
                left: 7% !important;
                width: 160px;
                height: 160px;
            }
            ${({ theme }) => theme.mq.xxxl} {
                width: 175px;
                height: 175px;
            }
        }
        &:nth-of-type(6) {
            top: 46% !important;
            left: 56% !important;
            width: 62px;
            height: 62px;
            ${({ theme }) => theme.mq.lg} {
                width: 73px;
                height: 73px;
            }
            ${({ theme }) => theme.mq.xxxl} {
                width: 90px;
                height: 90px;
            }
        }
    }
`
