import styled from "styled-components";

export const StyledWrapper = styled('div')`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    svg{
        position: absolute;
        &:first-child{
            width: 25%;
            top: 24% !important;
            left: 25% !important;
            ${({ theme }) => theme.mq.sm} {
                top: 29% !important;
                left: 25% !important;
            }
            ${({ theme }) => theme.mq.smm} {
                top: 33% !important;
                left: 24% !important;
            }
            ${({ theme }) => theme.mq.md} {
                top: 36% !important;
            }
            ${({ theme }) => theme.mq.xl} {
                top: 39% !important;
                left: 28% !important;
            }
        }
    }
`;
