import React from 'react';

const Heart = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 46 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.6643 11.2569C25.4666 9.09199 28.1471 7.85247 30.9639 7.88135C37.2178 7.88135 41.42 13.7604 41.42 19.2351C41.42 30.6796 27.1721 39.4469 23.6643 39.4469C20.1566 39.4469 5.90869 30.6796 5.90869 19.2351C5.90869 13.7604 10.1109 7.88135 16.3648 7.88135C19.1816 7.85247 21.862 9.09199 23.6643 11.2569Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.5 18.1147V28.8856"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.1146 23.5002H28.8855"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Heart;
