import React, { useRef, useEffect } from 'react';
import { isBrowser } from 'react-device-detect';

import AloeBackground from 'components/Gel/Background';
import { gelAnimation } from 'theme/Animations';
import parse from 'html-react-parser';

import {
  StyledSectionWrapper,
  StyledSection,
  StyledContent,
  StyledTitle,
  StyledProductWrapper,
  StyledColFirst,
  StyledTextWrapper,
  StyledText,
  StyledColImage,
  StyledProductsColWrapper,
  StyledColSecond,
  StyledProduct,
  StyledBgItem,
} from './GelStyles';

const Gel = ({ data }) => {
  let container = useRef(null);
  let title = useRef(null);
  let firstCol = useRef(null);
  let middleCol = useRef(null);
  let lastCol = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      gelAnimation(container, title, firstCol, middleCol, lastCol);
    }
  }, []);

  return (
    <StyledSectionWrapper>
      <AloeBackground data={data.background} />

      <StyledSection
        ref={el => {
          container = el;
        }}
      >
        <StyledContent>
          <StyledTitle
            ref={el => {
              title = el;
            }}
          >
            {parse(data.title)}
          </StyledTitle>
        </StyledContent>

        <StyledProductWrapper>
          <StyledColFirst
            ref={el => {
              firstCol = el;
            }}
          >
            <StyledTextWrapper>
              <StyledText>{parse(data.text_left)}</StyledText>
            </StyledTextWrapper>

            {data.images[0] && (
              <StyledColImage
                src={data.images[0].localFile.childImageSharp.fluid.src}
              />
            )}
          </StyledColFirst>

          <StyledProductsColWrapper
            ref={el => {
              middleCol = el;
            }}
            onClick={() => {
              if (data.images[1].caption) {
                window.open(data.images[1].caption, '_blank');
              }
            }}
          >
            {data.images[1] && (
              <StyledProduct
                src={data.images[1].localFile.childImageSharp.fluid.src}
              />
            )}
          </StyledProductsColWrapper>

          <StyledColSecond
            ref={el => {
              lastCol = el;
            }}
          >
            {data.images[2] && (
              <StyledColImage
                src={data.images[2].localFile.childImageSharp.fluid.src}
              />
            )}

            <StyledTextWrapper>
              <StyledText>{parse(data.text_right)}</StyledText>
            </StyledTextWrapper>
          </StyledColSecond>
        </StyledProductWrapper>

        {data.images[3] && (
          <StyledBgItem
            src={data.images[3].localFile.childImageSharp.fluid.src}
          />
        )}
      </StyledSection>
    </StyledSectionWrapper>
  );
};

export default Gel;
