import React, { useRef, useEffect } from 'react';
import { isBrowser } from 'react-device-detect';
import { suggestionsAnimation } from 'theme/Animations';
import withLocale from 'hoc/withLocale';
import elipse from 'svg/ellipse.svg';
import parse from 'html-react-parser';

import {
  StyledSuggestions,
  StyledImagesWrapper,
  StyledImageWrapper,
  StyledImage,
  StyledContentWrapper,
  StyledHeading,
  StyledText,
  StyledEllipse,
  StyledHeadingWrapper,
} from './SuggestionsStyles';

const Ritual = ({ data, currentLocale }) => {
  let items = useRef(null);
  const imagesWrapper = useRef(null);

  const randomUniqueNum = (range, outputCount) => {
    const arr = [];
    for (let i = 0; i < range; i += 1) {
      arr.push(i);
    }

    const result = [];

    for (let i = 1; i <= outputCount; i += 1) {
      const random = Math.floor(Math.random() * (range - i));
      result.push(arr[random]);
      arr[random] = arr[range - i];
    }

    return result;
  };

  const randomNumFromRange = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

  const getRandomImages = images => {
    const imagesCount = images.length >= 6 ? 6 : images.length;

    const randomIndex = randomUniqueNum(images.length, imagesCount);

    const randomImages = [];

    randomIndex.forEach(index => {
      randomImages.push(images[index]);
    });

    return randomImages;
  };

  const initImages = images => {
    const imagesCount = images.length >= 6 ? 6 : images.length;

    return images.slice(0, imagesCount).map((image, index) => (
      <StyledImageWrapper
        key={index}
        onClick={() => {
          if (image.caption) {
            const links = image.caption.split('|');
            window.open(
              currentLocale === 'en' && links[1] ? links[1] : links[0],
              '_blank'
            );
          }
        }}
      >
        <StyledImage imageSrc={image.localFile.publicURL} />
        <StyledImage />
      </StyledImageWrapper>
    ));
  };

  useEffect(() => {
    if (isBrowser) {
      suggestionsAnimation(items, items.children);
    }

    const boxes = [...imagesWrapper.current.children];
    let currentLayer = 1;
    let nextLayer = 2;

    const intervalId = setInterval(() => {
      const randomImages = getRandomImages(data.images);
      const randomBoxes = randomUniqueNum(boxes.length, boxes.length);

      randomBoxes.forEach(index => {
        boxes[index].children[
          nextLayer - 1
        ].style.backgroundImage = `url(${randomImages[index].localFile.publicURL})`;

        const delay = randomNumFromRange(2000, 3000);

        setTimeout(() => {
          boxes[index].children[nextLayer - 1].style.opacity = 0;
          boxes[index].children[currentLayer - 1].style.opacity = 1;
        }, delay);
      });

      currentLayer === 1 ? (currentLayer = 2) : (currentLayer = 1);
      nextLayer === 1 ? (nextLayer = 2) : (nextLayer = 1);
    }, 7000);

    return () => clearInterval(intervalId);
  });

  return (
    <StyledSuggestions>
      <StyledContentWrapper
        ref={el => {
          items = el;
        }}
      >
        <StyledHeadingWrapper>
          <StyledEllipse src={elipse} />
          <StyledHeading>{parse(data.title)}</StyledHeading>
        </StyledHeadingWrapper>
        <StyledText>{parse(data.text)}</StyledText>
      </StyledContentWrapper>
      <StyledImagesWrapper ref={imagesWrapper}>
        {initImages(data.images)}
      </StyledImagesWrapper>
    </StyledSuggestions>
  );
};

export default withLocale(Ritual);
