import styled from "styled-components";

export const StyledWrapper = styled('div')`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    svg{
        position: absolute;
        &:first-child{
            width: 46%;
            top: 5% !important;
            left: 3% !important;
            ${({ theme }) => theme.mq.md} {
                width: 65%;
                top: 12% !important;
                left: -3% !important;
            }
            ${({ theme }) => theme.mq.xl} {
                top: 10% !important;
            }
            ${({ theme }) => theme.mq.xxxl} {
                top: 19% !important;
                left: -9%;
            }
        }
        &:nth-child(2){
            width: 20px;
            left: 72% !important;
            top: 50% !important;
        }
        &:nth-child(3){
            width: 11%;
            left: 75% !important;
            top: 12% !important;
        }
    }
`;
