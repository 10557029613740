import React from 'react';
import styled from 'styled-components';

const StyledCompanyName = styled('svg')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90px;
  ${({ theme }) => theme.mq.md} {
    width: 118px;
  }
`;

const CompanyName = () => (
  <StyledCompanyName
    width="118"
    height="61"
    viewBox="0 0 118 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5624 25.8599V14.3542H2.66084V25.8599H0V0.21875H2.66084V11.6696H16.5624V0.21875H19.2232V25.8599H16.5624Z"
      fill="white"
    />
    <path
      d="M35.3511 26.1342C31.7671 26.1342 28.7261 24.8193 26.2282 22.299C23.7303 19.7787 22.427 16.6558 22.427 13.0945C22.427 9.47845 23.7303 6.41028 26.2282 3.83521C28.7261 1.31493 31.8214 0 35.3511 0C38.8808 0 41.976 1.26014 44.5283 3.83521C47.0262 6.35549 48.3294 9.47845 48.3294 13.0945C48.3294 16.6558 47.0805 19.7787 44.5283 22.299C41.976 24.8193 38.8808 26.1342 35.3511 26.1342ZM35.3511 2.68465C32.5273 2.68465 30.0837 3.72563 28.0745 5.75281C26.0653 7.78 25.0335 10.2455 25.0335 13.0945C25.0335 15.9435 26.0653 18.409 28.0745 20.4362C30.0837 22.4634 32.5273 23.5044 35.3511 23.5044C38.1748 23.5044 40.6184 22.4634 42.6277 20.4362C44.5826 18.3542 45.6143 15.8887 45.6143 13.0397C45.6143 10.1907 44.5826 7.72521 42.5734 5.69802C40.6184 3.72563 38.1748 2.68465 35.3511 2.68465Z"
      fill="white"
    />
    <path
      d="M51.8591 25.8599V0.21875H54.52V23.1752H66.1408V25.8599H51.8591Z"
      fill="white"
    />
    <path
      d="M72.7114 0.273438H70.0505V25.9146H72.7114V0.273438Z"
      fill="white"
    />
    <path
      d="M90.9029 25.8599L82.1602 15.1213L80.2053 17.5868V25.8599H77.5444V0.273539H80.2053V13.2585L90.5771 0.21875H93.9982L83.8436 12.9845L94.3783 25.8599H90.9029Z"
      fill="white"
    />
    <path
      d="M115.285 25.8599L112.244 18.4634H100.786L97.7452 25.8599H95.03L105.619 0.21875H107.357L117.946 25.8599H115.285ZM111.104 15.7787L106.488 4.54706L101.872 15.7787H111.104Z"
      fill="white"
    />
    <path
      d="M16.5624 56.5415V45.0359H2.66084V56.5415H0V30.9004H2.66084V42.3512H16.5624V30.9004H19.2232V56.5415H16.5624Z"
      fill="white"
    />
    <path
      d="M35.3511 56.8158C31.7671 56.8158 28.7261 55.5009 26.2282 52.9806C23.7303 50.4604 22.427 47.3374 22.427 43.7761C22.427 40.1601 23.7303 37.0919 26.2282 34.5168C28.7261 31.9966 31.8214 30.6816 35.3511 30.6816C38.9351 30.6816 41.976 31.9418 44.5283 34.5168C47.0262 37.0371 48.3294 40.1601 48.3294 43.7761C48.3294 47.3374 47.0805 50.4604 44.5283 52.9806C41.976 55.5009 38.8808 56.8158 35.3511 56.8158ZM35.3511 33.3663C32.5273 33.3663 30.0837 34.4073 28.0745 36.4345C26.0653 38.4616 25.0335 40.9271 25.0335 43.7761C25.0335 46.6251 26.0653 49.0906 28.0745 51.1178C30.0837 53.145 32.5273 54.186 35.3511 54.186C38.1748 54.186 40.6184 53.145 42.6277 51.1178C44.5826 49.0359 45.6143 46.5704 45.6143 43.7213C45.6143 40.8723 44.5826 38.4068 42.5734 36.3797C40.6184 34.4073 38.1748 33.3663 35.3511 33.3663Z"
      fill="white"
    />
    <path
      d="M51.8591 56.5415V30.9004H54.52V53.8569H66.1408V56.5415H51.8591Z"
      fill="white"
    />
    <path d="M72.7114 30.9561H70.0505V56.5972H72.7114V30.9561Z" fill="white" />
    <path
      d="M90.9029 56.5415L82.1602 45.8029L80.2053 48.2684V56.5415H77.5444V30.9552H80.2053V43.9401L90.5771 30.9004H93.9982L83.8436 43.6662L94.3783 56.5415H90.9029Z"
      fill="white"
    />
    <path
      d="M115.285 56.5415L112.244 49.145H100.786L97.7452 56.5415H95.03L105.619 30.9004H107.357L117.946 56.5415H115.285ZM111.104 46.4604L106.488 35.2287L101.872 46.4604H111.104Z"
      fill="white"
    />
  </StyledCompanyName>
);

export default CompanyName;
