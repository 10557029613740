import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage } from 'gatsby-plugin-image';

export const StyledClean = styled('div')`
  width: 100vw;
  min-height: 300px;
  position: relative;
  ${({ theme }) => theme.mq.smm} {
    min-height: 500px;
  }
  ${({ theme }) => theme.mq.md} {
    min-height: 700px;
  }
  ${({ theme }) => theme.mq.xxl} {
    min-height: 100vh;
  }
`;

export const StyledContent = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  padding: 4.5rem 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.md} {
    padding: 6rem 0 4.5rem;
  }
  ${({ theme }) => theme.mq.xl} {
    padding: 10rem 0 4.5rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    padding: 12rem 0 4.5rem;
  }
`;

export const StyledTitle = styled('h2')`
  align-self: center;
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 2.4rem;
  text-transform: uppercase;
  ${({ theme }) => theme.mq.md} {
    text-transform: none;
    font-size: 8.6rem;
    line-height: 6.4rem;
  }
`;

export const StyledSubtitle = styled('span')`
  display: none;
  align-self: center;
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 4.2rem;
  margin-top: 4.8rem;
  padding: 0 1rem;
  ${({ theme }) => theme.mq.md} {
    display: block;
  }
`;

export const StyledProducts = styled('div')`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  min-height: 300px;
  ${({ theme }) => theme.mq.smm} {
    min-height: 500px;
  }
  ${({ theme }) => theme.mq.md} {
    min-height: 700px;
  }
  ${({ theme }) => theme.mq.xxl} {
    min-height: 100vh;
  }
`;

export const StyledProduct = styled(BackgroundImage)`
  width: 25%;
  background: green;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
`;

export const StyledImageWrapper = styled('div')`
  display: block;
  position: absolute;
  width: 90%;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  ${({ theme }) => theme.mq.md} {
    bottom: -70px;
    width: 80%;
  }
  ${({ theme }) => theme.mq.xl} {
    bottom: -130px;
    width: 60%;
  }
`;

export const StyledImage = styled(GatsbyImage)`
  cursor: pointer;
  display: block;
`;
