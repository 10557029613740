import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

export const StyledMasks = styled('div')`
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: ${({ theme }) => theme.colors.purpleLightest};
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    bottom: -120px;
    left: -120px;
    border-radius: 50%;
    background: linear-gradient(
      162.79deg,
      #503199 -0.74%,
      rgba(80, 49, 153, 0) 88.17%
    );
    width: 263px;
    height: 263px;
    ${({ theme }) => theme.mq.lg} {
      bottom: -200px;
      left: -200px;
      width: 560px;
      height: 560px;
    }
  }
`;

export const StyledContainer = styled('div')`
  padding: 4.7rem 0 0;
  ${({ theme }) => theme.mq.lg} {
    padding: 10rem 0 0;
  }
  ${({ theme }) => theme.mq.xxl} {
    padding: 21rem 0 0;
  }
`;

export const StyledMainSection = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  ${({ theme }) => theme.mq.lg} {
    max-width: 950px;
  }
  ${({ theme }) => theme.mq.xxl} {
    flex-direction: row-reverse;
    justify-content: flex-end;
    max-width: 100%;
  }
  &::before {
    content: '';
    position: absolute;
    top: 110px;
    left: 200px;
    border-radius: 50%;
    background: #503199;
    width: 140px;
    height: 140px;
    ${({ theme }) => theme.mq.lg} {
      top: -100px;
      left: 35%;
      width: 380px;
      height: 380px;
    }
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50px;
    border-radius: 50%;
    background: #503199;
    width: 73px;
    height: 73px;
    ${({ theme }) => theme.mq.lg} {
      left: 55%;
      width: 128px;
      height: 128px;
    }
  }
`;

export const StyledSecondSection = styled('div')`
  background: linear-gradient(180deg, #432884 0%, #613cbb 100%);
  padding: 0 0 3rem;
  ${({ theme }) => theme.mq.lg} {
    padding: 0 0 12rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    padding: 0 0 22rem;
  }
`;

export const StyledImg = styled(GatsbyImage)`
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
`;

export const StyledSection = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 600px;
  margin: 0 auto;
  padding-top: 8rem;
  ${({ theme }) => theme.mq.lg} {
    max-width: 1000px;
    flex-direction: row;
    padding-top: 14rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    max-width: 1500px;
  }
`;

export const StyledContent = styled('div')`
  display: block;
  max-width: 950px;
`;

export const StyledTitle = styled('div')`
  position: relative;
  p {
    font-size: 2.9rem;
    line-height: 4.8rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
    padding: 0 3rem 8rem;
    text-align: center;
    ${({ theme }) => theme.mq.xxl} {
      padding: 4rem 3rem 8rem;
      font-size: 6rem;
      line-height: 9.4rem;
      text-align: left;
    }
    ${({ theme }) => theme.mq.xxxl} {
      font-size: 7.8rem;
      transform: translateX(-50px);
    }
    strong {
      display: block;
      &.color {
        display: inline;
        color: ${({ theme }) => theme.colors.red};
      }
      font-size: 3.4rem;
      ${({ theme }) => theme.mq.xxl} {
        font-size: 7.8rem;
      }
    }
  }
  img {
    position: absolute;
    top: -10px;
    left: 60px;
    ${({ theme }) => theme.mq.lg} {
      display: none;
    }
  }
`;

export const StyledSwiper = styled('div')`
  max-width: 1250px;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 9;
  ${({ theme }) => theme.mq.xxl} {
    max-width: 1000px;
  }
  ${({ theme }) => theme.mq.xxxl} {
    max-width: 1250px;
    transform: translateX(-100px);
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 80px;
    z-index: 10;
  }
  &::before {
    left: 0;
    background: linear-gradient(
      90deg,
      #432883 -36.75%,
      rgba(67, 40, 131, 0) 89.46%
    );
    ${({ theme }) => theme.mq.xxxl} {
      left: 0;
      width: 120px;
    }
  }
  &::after {
    right: 0;
    background: linear-gradient(
      -90deg,
      #432883 -36.75%,
      rgba(67, 40, 131, 0) 89.46%
    );
    ${({ theme }) => theme.mq.xxl} {
      display: none;
    }
  }
`;

export const StyledSubTitle = styled('div')`
  font-size: 2.4rem;
  line-height: 4.8rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
  padding: 0 2rem 1rem;
  ${({ theme }) => theme.mq.xxl} {
    font-size: 4.8rem;
    line-height: 7.2rem;
  }
  span {
    &.line {
      display: block;
    }
    &.color {
      display: inline;
      color: ${({ theme }) => theme.colors.pink};
    }
  }
`;

export const StyledParagraph = styled('div')`
  font-size: 1.8rem;
  line-height: 3.2rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white};
  padding: 0 2rem;
  position: relative;
  z-index: 2;
  ${({ theme }) => theme.mq.xxl} {
    font-size: 2.4rem;
    line-height: 4.8rem;
  }
  span {
    display: block;
  }
`;

export const StyledFace = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  ${({ theme }) => theme.mq.xxl} {
    margin-right: 100px;
  }
  img {
    object-fit: contain;
    position: absolute;
    transform: translate(-50%, -50%);
    &:nth-of-type(1) {
      z-index: 5;
      top: 50%;
      left: 50%;
      width: 160px;
      height: 220px;
      ${({ theme }) => theme.mq.lg} {
        left: 30% !important;
      }
      ${({ theme }) => theme.mq.xxl} {
        top: 0 !important;
        left: 20% !important;
        width: 280px;
        height: 450px;
      }
    }
    &:nth-of-type(2) {
      top: 35% !important;
      left: 28% !important;
      width: 35px;
      height: 35px;
      ${({ theme }) => theme.mq.xxl} {
        top: 25% !important;
        left: 15% !important;
        width: 75px;
        height: 85px;
      }
    }
    &:nth-of-type(3) {
      top: 46% !important;
      left: 59% !important;
      width: 140px;
      height: 140px;
      ${({ theme }) => theme.mq.xxl} {
        top: -1% !important;
        left: 62% !important;
        width: 230px;
        height: 250px;
      }
    }
    &:nth-of-type(4) {
      top: 18% !important;
      left: 42% !important;
      width: 29px;
      height: 29px;
      ${({ theme }) => theme.mq.xxl} {
        top: -10% !important;
        left: 35% !important;
        width: 64px;
        height: 64px;
      }
    }
    &:nth-of-type(5) {
      top: 55% !important;
      left: 37% !important;
      width: 64px;
      height: 64px;
      ${({ theme }) => theme.mq.xxl} {
        top: 45% !important;
        left: 35% !important;
        width: 120px;
        height: 120px;
      }
    }
  }
`;
