import styled from 'styled-components';
import Img from 'gatsby-image';

export const StyledPoland = styled('div')`
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: ${({ theme }) => theme.colors.gray};
`;

export const StyledContainer = styled('div')`
  max-width: 600px;
  margin: 0 auto;
  ${({ theme }) => theme.mq.lg} {
    max-width: 1200px;
  }
  ${({ theme }) => theme.mq.xxl} {
    max-width: 1600px;
  }
`;

export const StyledWrapper = styled('div')`
  padding: 6rem 0 8rem;
  overflow: hidden;
  ${({ theme }) => theme.mq.lg} {
    padding: 10rem 0 20rem;
  }
`;

export const StyledSections = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
  }
`;

export const StyledSection = styled('div')`
  width: 100%;
  padding: 0 2.5rem;
  ${({ theme }) => theme.mq.lg} {
    width: 50%;
  }
`;

export const StyledMapSection = styled('div')`
  width: 100%;
  ${({ theme }) => theme.mq.lg} {
    width: 50%;
    padding: 0 2.5rem;
  }
`;

export const StyledTitle = styled('h2')`
  font-size: 3.6rem;
  margin: 0 0 6rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.purple};
  ${({ theme }) => theme.mq.lg} {
    font-size: 4.8rem;
    margin: 0 2.5rem 7rem;
    text-align: left;
  }
`;

export const StyledParagraph = styled('div')`
  font-size: 1.6rem;
  line-height: 2.8rem;
  margin: 0 0 5rem;
  ${({ theme }) => theme.mq.lg} {
    line-height: 3.6rem;
    margin: 0 4rem 6rem 0;
    &:nth-of-type(2) {
      margin: 0 4rem 0 0;
    }
  }
`;

export const StyledImageWrapper = styled('div')`
  height: 100%;
  width: 100%;
  max-height: 41px;
  max-width: 92px;
  margin: 0 0 2.5rem;
  ${({ theme }) => theme.mq.md} {
    max-height: 75px;
    max-width: 160px;
    margin: 0 0 2.8rem;
  }
`;

export const StyledImage = styled(Img)`
  cursor: pointer;
  height: 100%;
  width: 100%;
`;

export const MapContainer = styled('div')`
  height: 300px;
  ${({ theme }) => theme.mq.lg} {
    height: 100%;
  }
`;

export const StyledCompaniesSection = styled('div')`
  height: 100%;
  padding: 0 2.5rem;
`;

export const StyledCompaniesWrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  grid-template-rows: 30px repeat(auto-fill, 1fr);
  ${({ theme }) => theme.mq.lg} {
    grid-template-columns: repeat(10, 1fr);
  }
`;

export const StyledCompanyWrapper = styled('div')`
  padding: 1rem 1.6rem;
  background: ${({ theme }) => theme.colors.white};
`;

export const StyledCompany = styled(Img)`
  height: 100%;
  width: 100%;
`;

export const StyledIcon = styled('div')`
  display: flex;
  height: 56px;
  width: 56px;
  ${({ theme }) => theme.mq.lg} {
    height: 42px;
    width: 42px;
  }
  svg {
    height: 100%;
    width: 100%;
  }
`;

export const StyledCompanyTitle = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
    margin-bottom: 2.5rem;
  }
  h3 {
    p {
      font-size: 2.4rem;
      line-height: 3.6rem;
      text-align: center;
      margin-top: 1rem;
      color: ${({ theme }) => theme.colors.purple};
      ${({ theme }) => theme.mq.lg} {
        font-size: 3.6rem;
        line-height: 4.4rem;
        text-align: left;
        margin-top: 0rem;
        margin-left: 1rem;
      }
    }
  }
`;

export const StyledCompanyContent = styled('div')`
  margin: 6rem 0 2.5rem;
  ${({ theme }) => theme.mq.lg} {
    margin: 16rem 0 7rem;
  }
  p {
    max-width: 830px;
    margin: 0 auto;
    text-align: center;
    color: ${({ theme }) => theme.colors.black};
    font-size: 1.6rem;
    line-height: 2.8rem;
    ${({ theme }) => theme.mq.lg} {
      font-size: 1.8rem;
      line-height: 3.6rem;
    }
  }
`;
