import React, { useEffect, useRef } from 'react';
import parse from 'html-react-parser';
import { isBrowser } from 'react-device-detect';
import withLocale from 'hoc/withLocale';
import { getImage } from 'gatsby-plugin-image';
import { moisturizeAnimation } from 'theme/Animations';

import {
  StyledMoisturize,
  StyledContent,
  StyledTitle,
  StyledSubtitle,
  StyledImageWrapper,
  StyledImage,
} from './MoisturizeStyles';

const Moisturize = ({ data, currentLocale }) => {
  const gatsbyImage = getImage(data.image.localFile);

  let container = useRef(null);
  let content = useRef(null);
  let image = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      moisturizeAnimation(container, content, image);
    }
  }, []);

  return (
    <StyledMoisturize
      ref={el => {
        container = el;
      }}
    >
      <StyledContent
        ref={el => {
          content = el;
        }}
      >
        <StyledTitle>{parse(data.title)}</StyledTitle>
        <StyledSubtitle>{parse(data.text)}</StyledSubtitle>
      </StyledContent>
      <StyledImageWrapper
        ref={el => {
          image = el;
        }}
        onClick={() => {
          if (data.image.caption) {
            const links = data.image.caption.split('|');
            window.open(
              currentLocale === 'en' && links[1] ? links[1] : links[0],
              '_blank'
            );
          }
        }}
      >
        <StyledImage
          image={gatsbyImage}
          alt={data.image.alternativeText ?? 'moisturize'}
        />
      </StyledImageWrapper>
    </StyledMoisturize>
  );
};

export default withLocale(Moisturize);
