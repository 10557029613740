import styled from 'styled-components';

export const StyledRitualWrapper = styled('div')`
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 12rem 2.5rem;
  background: ${({ theme }) => theme.colors.yellow};
  ${({ theme }) => theme.mq.md} {
    padding: 16rem 2.5rem;
  }
  ${({ theme }) => theme.mq.xl} {
    padding: 16rem 4.5rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    padding: 20rem 4.5rem;
  }
`;

export const StyledImageWrapper = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  order: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledTextWrapper = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  order: 2;
`;

export const StyledText = styled('div')`
  font-size: 2rem;
  line-height: 3.2rem;
  width: 100%;
  text-align: center;
  margin: 0;
  color: ${({ theme }) => theme.colors.purple};
  ${({ theme }) => theme.mq.sm} {
    font-size: 2.5rem;
    line-height: 3.6rem;
  }
  ${({ theme }) => theme.mq.md} {
    font-size: 3rem;
    line-height: 4rem;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 3.6rem;
    line-height: 5rem;
  }
  ${({ theme }) => theme.mq.xl} {
    font-size: 3.2rem;
    line-height: 4.5rem;
    text-align: right;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 3.8rem;
    line-height: 5.5rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    font-size: 4.2rem;
    line-height: 6.4rem;
  }
`;

export const StyledItemWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 300px 1fr;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto 12rem auto;
  grid-gap: 3rem;
  &:last-child {
    margin-bottom: 0;
  }
  ${({ theme }) => theme.mq.md} {
    margin-bottom: 20rem;
  }
  ${({ theme }) => theme.mq.lg} {
    grid-template-rows: 350px 1fr;
    max-width: 950px;
  }
  ${({ theme }) => theme.mq.xl} {
    grid-template-columns: 350px 1fr;
    grid-template-rows: 1fr;
    grid-gap: 8rem;
    &:nth-child(even) {
      grid-template-columns: 1fr 350px;
      ${StyledImageWrapper} {
        order: 2;
      }
      ${StyledTextWrapper} {
        order: 1;
      }
      ${StyledText} {
        text-align: left;
      }
    }
  }
  ${({ theme }) => theme.mq.xxl} {
    max-width: 1400px;
    grid-gap: 6rem;
    margin-bottom: 30rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    grid-template-columns: 380px 1fr;
    &:nth-child(even) {
      grid-template-columns: 1fr 380px;
    }
  }
`;
