import React, { useEffect, useRef } from 'react';
import Parallax from 'parallax-js';
import { graphql, useStaticQuery } from 'gatsby';
import { isBrowser } from 'react-device-detect';
import { StyledBgWrapper, StyledBg } from './BackgroundStyles';

const SliderBackground = () => {
  const scene = useRef(null);

  const data = useStaticQuery(graphql`
    {
      bg: file(
        relativeDirectory: { eq: "clean_slider/bg" }
        name: { eq: "bg" }
      ) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (isBrowser) {
      const parallaxInstance = new Parallax(scene.current);
      parallaxInstance.enable();

      return () => parallaxInstance.disable();
    }
  }, []);

  return (
    <StyledBgWrapper ref={scene}>
      <StyledBg fluid={data.bg.childImageSharp.fluid} data-depth="-0.05" />
    </StyledBgWrapper>
  );
};

export default SliderBackground;
