import React, { forwardRef } from 'react';
import parse from 'html-react-parser';
import {
  StyledFunction,
  StyledFunctionTitle,
  StyledIcon,
} from './MakeupIconStyles';
import IconFace from '../atoms/icons/face';

const MakeupIcon = (props, ref) => (
  <StyledFunction ref={ref}>
    <StyledFunctionTitle>{parse(props.text)}</StyledFunctionTitle>
    <StyledIcon>
      <IconFace />
    </StyledIcon>
  </StyledFunction>
);

export default forwardRef(MakeupIcon);
