import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

export const StyledBackground = styled(BackgroundImage)`
  width: 100%;
  min-height: 500px;
  position: relative;
  ${({ theme }) => theme.mq.lg} {
    min-height: 100vh;
  }
`;

export const StyledWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8rem 0 10rem;
  ${({ theme }) => theme.mq.lg} {
    padding: 20rem 0 10rem;
  }
`;

export const StyledPanel = styled('div')`
  display: flex;
  background: ${({ $color, theme }) => $color ?? theme.colors.green};
  padding: 2rem 2.7rem;
  margin: 0 0 3.3rem;
  ${({ theme }) => theme.mq.lg} {
    padding: 2.8rem 5rem;
  }
  &:nth-of-type(1) {
    min-width: 160px;
    ${({ theme }) => theme.mq.lg} {
      min-width: 245px;
    }
  }
  &:nth-of-type(2) {
    min-width: 211px;
    ${({ theme }) => theme.mq.lg} {
      min-width: 338px;
    }
  }
  &:nth-of-type(3) {
    min-width: 263px;
    ${({ theme }) => theme.mq.lg} {
      min-width: 416px;
    }
  }
  &:nth-of-type(4) {
    min-width: 315px;
    ${({ theme }) => theme.mq.lg} {
      min-width: 517px;
    }
  }
  &:nth-of-type(5) {
    min-width: 100%;
    ${({ theme }) => theme.mq.sm} {
      min-width: 375px;
    }
    ${({ theme }) => theme.mq.lg} {
      min-width: 630px;
    }
  }
`;

export const StyledTitle = styled('h4')`
  font-size: 2.4rem;
  line-height: 2.9rem;
  padding-left: 1.8rem;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.lg} {
    font-size: 3.6rem;
    line-height: 4.4rem;
    padding-left: 5rem;
  }
`;

export const StyledNumber = styled('span')`
  font-size: 2.4rem;
  line-height: 2.9rem;
  color: rgba(255, 255, 255, 0.21);
  ${({ theme }) => theme.mq.lg} {
    font-size: 3.6rem;
    line-height: 4.4rem;
  }
`;

export const StyledContact = styled('div')`
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.8rem 5rem;
  ${({ theme }) => theme.mq.lg} {
    display: block;
  }
`;

export const StyledSocial = styled('div')`
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 3.6rem 5rem;
  ${({ theme }) => theme.mq.lg} {
    display: block;
  }
`;
