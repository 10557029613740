import React, { useRef, useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { graphql, useStaticQuery } from 'gatsby';
import { Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import withLocale from 'hoc/withLocale';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { isMobile, isBrowser } from 'react-device-detect';
import parse from 'html-react-parser';

import { polandAnimation } from 'theme/Animations';

import Icon from 'components/atoms/icons/icon';
import Map from 'components/Map/Map';
import markerIcon from './marker.png';

import {
  StyledPoland,
  StyledContainer,
  StyledWrapper,
  StyledTitle,
  StyledParagraph,
  StyledImageWrapper,
  StyledImage,
  StyledSections,
  StyledSection,
  StyledMapSection,
  MapContainer,
  StyledCompaniesSection,
  StyledCompaniesWrapper,
  StyledCompanyWrapper,
  StyledCompany,
  StyledCompanyTitle,
  StyledCompanyContent,
  StyledIcon,
} from './PolandStyles';

require('react-leaflet-markercluster/dist/styles.min.css');

const Poland = ({ data, currentLocale }) => {
  const intl = useIntl();

  const mapSettings = {
    center: [52, 19],
    defaultBaseMap: 'OpenStreetMap',
    zoom: 6,
    maxZoom: 14,
  };

  let container = useRef(null);
  let title = useRef(null);
  let map = useRef(null);
  let content = useRef(null);
  let companies = useRef(null);
  let customMarker;

  if (typeof window !== `undefined`) {
    customMarker = L.icon({
      iconUrl: markerIcon,
      iconSize: [30, 30],
    });
  }
  const destinationsData = useStaticQuery(graphql`
    {
      destinations: allStrapiShopAdresses {
        nodes {
          name
          email
          phone
          content
          street
          city
          postal_code
          website
          latitude
          longitude
        }
      }
    }
  `);

  useEffect(() => {
    if (isBrowser) {
      polandAnimation(container, title, map, content, companies);
    }
  }, []);

  const CompanySection = () => (
    <div
      ref={el => {
        content = el;
      }}
    >
      <StyledImageWrapper
        onClick={() => {
          if (data.image.caption) {
            const links = data.image.caption.split('|');
            window.open(
              currentLocale === 'en' && links[1] ? links[1] : links[0],
              '_blank'
            );
          }
        }}
      >
        <StyledImage
          title={data.image.alternativeText ?? 'my_asia'}
          alt={data.image.alternativeText ?? 'my_asia'}
          fluid={data.image.localFile.childImageSharp.fluid}
          imgStyle={{ objectFit: 'contain' }}
        />
      </StyledImageWrapper>
      <StyledParagraph>{parse(data.text)}</StyledParagraph>
      {intl.locale === 'pl' && (
        <>
          <StyledImageWrapper
            onClick={() => {
              if (data.image2.caption) {
                const links = data.image2.caption.split('|');
                window.open(
                  currentLocale === 'en' && links[1] ? links[1] : links[0],
                  '_blank'
                );
              }
            }}
          >
            <StyledImage
              title={data.image2.alternativeText ?? 'holika_eu'}
              alt={data.image2.alternativeText ?? 'holika_eu'}
              fluid={data.image2.localFile.childImageSharp.fluid}
              imgStyle={{ objectFit: 'contain' }}
            />
          </StyledImageWrapper>
          <StyledParagraph>{parse(data.text2)}</StyledParagraph>
        </>
      )}
    </div>
  );

  const MapSection = () => (
    <MapContainer
      ref={el => {
        map = el;
      }}
    >
      <Map {...mapSettings}>
        <MarkerClusterGroup>
          {destinationsData.destinations.nodes.map((destination, index) => {
            const { latitude, longitude, name, street, city } = destination;
            const postalCode = destination.postal_code;
            const position = [latitude, longitude];

            return (
              <div key={index}>
                {latitude && longitude && (
                  <Marker key={index} position={position} icon={customMarker}>
                    <Popup>
                      <div>{name}</div>
                      <div>{street}</div>
                      <div>
                        {postalCode}
                        {city}
                      </div>
                    </Popup>
                  </Marker>
                )}
              </div>
            );
          })}
        </MarkerClusterGroup>
      </Map>
    </MapContainer>
  );

  return (
    <StyledPoland>
      <StyledContainer>
        <StyledWrapper
          ref={el => {
            container = el;
          }}
        >
          <StyledTitle
            ref={el => {
              title = el;
            }}
          >
            {parse(data.title)}
          </StyledTitle>
          <StyledSections>
            <StyledSection>
              <CompanySection />
            </StyledSection>
            <StyledMapSection>
              <MapSection />
            </StyledMapSection>
          </StyledSections>
          {isBrowser && (
            <StyledCompaniesSection
              ref={el => {
                companies = el;
              }}
            >
              <StyledCompanyContent>
                <StyledCompanyTitle>
                  <StyledIcon>
                    <Icon name="Shop" />
                  </StyledIcon>
                  <h3>{parse(data.title2)}</h3>
                </StyledCompanyTitle>
                {parse(data.text3)}
              </StyledCompanyContent>
              <StyledCompaniesWrapper>
                {data.images.map((item, idx) => (
                  <StyledCompanyWrapper key={idx}>
                    <StyledCompany
                      title={item.alternativeText ?? 'Photo image'}
                      alt={item.alternativeText ?? 'Photo'}
                      fluid={item.localFile.childImageSharp.fluid}
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  </StyledCompanyWrapper>
                ))}
              </StyledCompaniesWrapper>
            </StyledCompaniesSection>
          )}
          {isMobile && (
            <StyledCompaniesSection>
              <StyledCompanyContent>
                <StyledCompanyTitle>
                  <StyledIcon>
                    <Icon name="Shop" />
                  </StyledIcon>
                  <h3>{parse(data.title2)}</h3>
                </StyledCompanyTitle>
              </StyledCompanyContent>
              <StyledCompaniesWrapper>
                {data.images.map((item, idx) => (
                  <StyledCompanyWrapper key={idx}>
                    <StyledCompany
                      title={item.alternativeText ?? 'Photo image'}
                      alt={item.alternativeText ?? 'Photo'}
                      fluid={item.localFile.childImageSharp.fluid}
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  </StyledCompanyWrapper>
                ))}
              </StyledCompaniesWrapper>
            </StyledCompaniesSection>
          )}
        </StyledWrapper>
      </StyledContainer>
    </StyledPoland>
  );
};

export default withLocale(Poland);
