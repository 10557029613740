import React, { useEffect, useRef } from 'react';
import Parallax from 'parallax-js';
import { isBrowser } from 'react-device-detect';

import { StyledStep, StyledImg } from './StepStyles';

const Step1 = ({ image }) => {
  const scene = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      const parallaxInstance = new Parallax(scene.current);
      parallaxInstance.enable();

      return () => parallaxInstance.disable();
    }
  }, []);

  return (
    <StyledStep ref={scene}>
      <StyledImg
        src={image.localFile.childImageSharp.fluid.src}
        data-depth={(Math.random() - 0.5) / 2}
        alt={image.alternativeText}
      />
    </StyledStep>
  );
};

export default Step1;
