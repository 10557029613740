import React from 'react';

const Peeling = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.2036 27.5223C15.2036 27.7306 15.091 27.8994 14.9522 27.8994C14.8133 27.8994 14.7008 27.7306 14.7008 27.5223C14.7008 27.314 14.8133 27.1452 14.9522 27.1452"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9601 22.3138C10.901 22.4162 10.7701 22.4513 10.6677 22.3922C10.5654 22.3331 10.5303 22.2022 10.5894 22.0998C10.6485 21.9975 10.7794 21.9624 10.8818 22.0215"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.8265 27.3337C14.9653 27.3337 15.0779 27.5026 15.0779 27.7109"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8816 22.0216C10.9839 22.0807 11.019 22.2116 10.9599 22.3139"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2036 14.9519C15.2036 15.0908 15.091 15.2033 14.9522 15.2033C14.8133 15.2033 14.7008 15.0908 14.7008 14.9519C14.7008 14.8131 14.8133 14.7005 14.9522 14.7005"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1327 15.0852C15.0736 15.1875 14.9427 15.2226 14.8404 15.1635C14.738 15.1044 14.7029 14.9735 14.762 14.8712C14.8211 14.7688 14.952 14.7337 15.0544 14.7928"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.8265 14.8262C14.9653 14.8262 15.0779 14.9388 15.0779 15.0776"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0544 14.7925C15.1568 14.8516 15.1919 14.9824 15.1328 15.0848"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2036 2.38149C15.2036 2.58977 15.091 2.75861 14.9522 2.75861C14.8133 2.75861 14.7008 2.58977 14.7008 2.38149C14.7008 2.17322 14.8133 2.00438 14.9522 2.00438"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.306 7.85795C19.2469 7.96031 19.1161 7.99538 19.0137 7.93628C18.9113 7.87718 18.8763 7.7463 18.9354 7.64394C18.9945 7.54159 19.1253 7.50652 19.2277 7.56561"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.8265 1.75298C14.9653 1.75298 15.0779 1.86553 15.0779 2.00438"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.2278 7.56529C19.3301 7.62439 19.3652 7.75527 19.3061 7.85763"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.38174 15.2033C2.17347 15.2033 2.00463 15.0908 2.00463 14.9519C2.00463 14.8131 2.17347 14.7005 2.38174 14.7005C2.59001 14.7005 2.75885 14.8131 2.75885 14.9519"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.61233 10.9924C7.50997 10.9333 7.4749 10.8024 7.534 10.7C7.59309 10.5977 7.72398 10.5626 7.82633 10.6217C7.92869 10.6808 7.96376 10.8117 7.90466 10.914"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5703 14.8262C2.5703 14.9651 2.40146 15.0776 2.19318 15.0776"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.90481 10.9134C7.84571 11.0158 7.71483 11.0509 7.61247 10.9918"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.5226 15.2033C27.3143 15.2033 27.1455 15.0908 27.1455 14.9519C27.1455 14.8131 27.3143 14.7005 27.5226 14.7005C27.7309 14.7005 27.8997 14.8131 27.8997 14.9519"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.0684 19.3371C21.966 19.278 21.931 19.1471 21.9901 19.0448C22.0491 18.9424 22.18 18.9073 22.2824 18.9664C22.3847 19.0255 22.4198 19.1564 22.3607 19.2588"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.3609 19.2585C22.3018 19.3608 22.1709 19.3959 22.0685 19.3368"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.72362 6.11579C5.65171 6.04389 5.6302 5.93576 5.66911 5.84181C5.70802 5.74786 5.7997 5.68661 5.90138 5.68661C6.00307 5.68661 6.09475 5.74786 6.13366 5.84181C6.17257 5.93576 6.15106 6.04389 6.07915 6.11579"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5807 6.97246C12.5583 6.88885 12.5885 6.79998 12.6571 6.74729C12.7258 6.69459 12.8194 6.68845 12.8944 6.73173C12.9694 6.77501 13.0109 6.85918 12.9996 6.945C12.9883 7.03082 12.9264 7.10138 12.8428 7.12378"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.07915 6.34879C5.98097 6.49605 5.8218 6.49605 5.72362 6.34879"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8429 7.12308C12.7287 7.15366 12.6114 7.08592 12.5808 6.97176"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.8252 23.8217C23.7533 23.7138 23.7318 23.5516 23.7707 23.4107C23.8096 23.2698 23.9013 23.1779 24.0029 23.1779C24.1046 23.1779 24.1963 23.2698 24.2352 23.4107C24.2741 23.5516 24.2526 23.7138 24.1807 23.8217"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.9012 23.0959C16.8788 23.0123 16.909 22.9234 16.9777 22.8708C17.0463 22.8181 17.14 22.8119 17.215 22.8552C17.2899 22.8985 17.3314 22.9827 17.3201 23.0685C17.3088 23.1543 17.2469 23.2248 17.1633 23.2472"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.1807 24.4502C24.0825 24.5974 23.9234 24.5974 23.8252 24.4502"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.1632 23.247C17.049 23.2776 16.9317 23.2099 16.9011 23.0957"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.11598 24.1803C6.04408 24.2523 5.93594 24.2738 5.84199 24.2349C5.74805 24.1959 5.68679 24.1043 5.68679 24.0026C5.68679 23.9009 5.74805 23.8092 5.84199 23.7703C5.93594 23.7314 6.04408 23.7529 6.11598 23.8248"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.94151 17.3445C6.85791 17.3669 6.76903 17.3367 6.71634 17.2681C6.66364 17.1994 6.65751 17.1057 6.70078 17.0308C6.74406 16.9558 6.82824 16.9143 6.91406 16.9256C6.99987 16.9369 7.07043 16.9988 7.09283 17.0824"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.34898 23.8248C6.49624 23.923 6.49624 24.0822 6.34898 24.1803"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.0928 17.0819C7.12339 17.1961 7.05564 17.3134 6.94148 17.344"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.8218 6.07897C23.7139 6.15087 23.5517 6.17238 23.4108 6.13347C23.2699 6.09456 23.178 6.00289 23.178 5.9012C23.178 5.79951 23.2699 5.70784 23.4108 5.66893C23.5517 5.63002 23.7139 5.65153 23.8218 5.72343"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.0646 13.0262C22.981 13.0486 22.8921 13.0185 22.8394 12.9498C22.7867 12.8811 22.7806 12.7875 22.8238 12.7125C22.8671 12.6376 22.9513 12.5961 23.0371 12.6074C23.1229 12.6187 23.1935 12.6805 23.2159 12.7641"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.4505 5.72343C24.5978 5.82161 24.5978 5.98079 24.4505 6.07897"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.2161 12.7637C23.2467 12.8779 23.1789 12.9952 23.0648 13.0258"
      stroke="white"
      strokeWidth="1.78497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Peeling;
