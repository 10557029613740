import React, { forwardRef } from 'react';
import parse from 'html-react-parser';
import {
  StyledFunction,
  StyledFunctionTitle,
  StyledIcon,
} from './FilterIconStyles';
import IconSun from '../atoms/icons/sun';

const FilterIcon = (props, ref) => (
  <StyledFunction ref={ref}>
    <StyledFunctionTitle>{parse(props.text)}</StyledFunctionTitle>

    <StyledIcon>
      <IconSun />
    </StyledIcon>
  </StyledFunction>
);

export default forwardRef(FilterIcon);
