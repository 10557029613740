import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

export const StyledSlide = styled('div')`
  position: relative;
  width: 100%;
  height: 81vw;
  padding-bottom: 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.mq.smm} {
    height: 500px;
  }
  ${({ theme }) => theme.mq.md} {
    height: 540px;
  }
  ${({ theme }) => theme.mq.lg} {
    height: 400px;
  }
  ${({ theme }) => theme.mq.xxl} {
    height: 500px;
  }
  ${({ theme }) => theme.mq.xxxl} {
    height: 700px;
  }
`;

export const StyledImgWrapper = styled('div')`
  top: 0;
  left: 0;
  &:first-child {
    z-index: 1;
    position: relative;
    max-width: 100%;
    max-height: 100%;
  }
`;

export const StyledImg = styled(GatsbyImage)``;
