import styled from 'styled-components';
import Slider from 'react-slick';

export const SliderWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSlider = styled(Slider)`
  width: 100vw;
  ${({ theme }) => theme.mq.sm} {
    width: 400px;
  }
  ${({ theme }) => theme.mq.smm} {
    width: 450px;
  }
  ${({ theme }) => theme.mq.xxl} {
    width: 550px;
  }
  ${({ theme }) => theme.mq.xxxl} {
    width: 600px;
  }
  .slick-dots {
    bottom: 25px;
    ${({ theme }) => theme.mq.xxl} {
      bottom: 50px;
    }
  }
  .ft-slick__dots--custom {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 0.5;
    transition: 0.2s;
    border-radius: 100%;
  }
  .slick-active {
    .ft-slick__dots--custom {
      transform: scale(1.2);
      opacity: 1;
    }
  }
`;

export const StyledCleanSlider = styled('div')`
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 8rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.mq.lg} {
    padding: 5rem 0 2rem 0;
  }
`;

export const StyledContainer = styled('div')`
  position: relative;
  max-width: 600px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.mq.smm} {
    grid-gap: 4rem;
  }
  ${({ theme }) => theme.mq.lg} {
    max-width: 950px;
    grid-template-columns: 1fr 400px;
  }
  ${({ theme }) => theme.mq.xxl} {
    max-width: 1200px;
    grid-template-columns: 1fr 500px;
    grid-gap: 5rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    max-width: 1400px;
    grid-template-columns: 1fr 550px;
  }
`;

export const StyledContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
  padding: 0 2.5rem;
  ${({ theme }) => theme.mq.lg} {
    margin-top: -8rem;
  }
  ${({ theme }) => theme.mq.xl} {
    margin-top: -6rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    margin-top: -10rem;
  }
`;

export const StyledTitle = styled('span')`
  font-size: 2.1rem;
  line-height: 3.8rem;
  display: inline-block;
  text-align: left;
  font-weight: 600;
  margin-right: 1rem;
  font-family: 'Montserrat', serif;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.lg} {
    font-size: 4.2rem;
    line-height: 4.5rem;
    display: block;
    margin-bottom: 3rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 4.8rem;
    line-height: 4.8rem;
    margin-bottom: 5rem;
  }
`;

export const StyledText = styled('div')`
  font-size: 2.1rem;
  line-height: 3.8rem;
  text-align: left;
  margin-top: 0;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.lg} {
    font-size: 2rem;
    line-height: 3.6rem;
    margin: 0 0 3rem 0;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 2.8rem;
    line-height: 4.8rem;
    margin: 0 0 5rem 0;
  }
`;

export const StyledButton = styled('button')`
  display: none;
  ${({ theme }) => theme.mq.lg} {
    display: block;
    text-align: center;
    border-radius: 4px;
    padding: 18px 50px;
    font-size: 1.8rem;
    line-height: 1.8rem;
    background-color: ${({ theme }) => theme.colors.yellow};
    color: ${({ theme }) => theme.colors.purple};
    font-weight: 600;
    transition: 0.3s;
    &:hover {
      background-color: ${({ theme }) => theme.colors.purpleDarkest};
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const StyledSliderWrapper = styled('div')`
  max-width: 300px;
  position: relative;
  z-index: 9;
  margin: 0 auto;
  ${({ theme }) => theme.mq.xxxl} {
    max-width: 650px;
  }
`;

export const StyledButtonWrapper = styled('div')`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 3.8rem;
  ${({ theme }) => theme.mq.sm} {
    padding: 0 4rem;
  }
  ${({ theme }) => theme.mq.lg} {
    display: none;
  }
`;

export const StyledButtonMobile = styled('button')`
  text-align: center;
  border-radius: 4px;
  padding: 19px 0;
  background-color: ${({ theme }) => theme.colors.yellow};
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
  width: 100%;
  font-size: 1.6rem;
  transition: 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.purpleDarkest};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const StyledImgWrapper = styled.div`
  cursor: pointer;
`;
