import React from 'react';

import {
  Desktop,
  Mobile,
  StyledSwipeUp,
  StyledSwipeUpWrapper,
  StyledText,
  StyledScroll,
  StyledScrollWrapper,
} from './ScrollUpStyles';

const ScrollUp = () => (
  <>
    <Desktop>
      <StyledScrollWrapper>
        <StyledScroll />
      </StyledScrollWrapper>
      <StyledText>Scroll Down</StyledText>
    </Desktop>
    <Mobile>
      <StyledSwipeUpWrapper>
        <StyledSwipeUp />
      </StyledSwipeUpWrapper>
      <StyledText>Swipe Up</StyledText>
    </Mobile>
  </>
);

export default ScrollUp;
