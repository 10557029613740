import styled from 'styled-components';

export const StyledSection = styled('div')`
  /* min-height: 100vh; */
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  &:before {
    content: '';
    height: 50%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background: linear-gradient(0deg, #ff595e 0%, #f7a9a9 100%);
    ${({ theme }) => theme.mq.xl} {
      height: 100%;
      width: 50%;
      background: linear-gradient(253.71deg, #ff595e 0%, #f7a9a9 100%);
    }
  }
  &:after {
    content: '';
    height: 50%;
    width: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 0;
    background: linear-gradient(0deg, #432883 0%, #61469f 100%);
    ${({ theme }) => theme.mq.xl} {
      top: 0;
      height: 100%;
      width: 50%;
      background: linear-gradient(253.71deg, #432883 0%, #61469f 100%);
    }
  }
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const StyledHeadingWrapper = styled('div')`
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  order: 2;
  ${({ theme }) => theme.mq.lg} {
    order: 3;
    min-height: auto;
  }
  ${({ theme }) => theme.mq.xxxl} {
    min-height: 200px;
  }
`;

export const StyledHeading = styled('div')`
  font-size: 5.1rem;
  line-height: 6.5rem;
  font-weight: 700;
  text-align: center;
  font-family: 'Montserrat', serif;
  color: ${({ theme }) => theme.colors.white};
  .typing-text {
    span {
      color: ${({ theme }) => theme.colors.pink};
    }
  }
  ${({ theme }) => theme.mq.xl} {
    font-size: 7.8rem;
    line-height: 9rem;
  }
`;
