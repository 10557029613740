import React, { useEffect, useRef } from 'react';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import 'swiper/swiper-bundle.css';
import Swiper from 'react-id-swiper';
import SwiperCore, { Pagination, Autoplay } from 'swiper/core';
import { isBrowser } from 'react-device-detect';
import { careAnimation } from 'theme/Animations';
import withLocale from 'hoc/withLocale';
import parse from 'html-react-parser';

import Water from 'svg/Water';
import Cream from 'svg/Cream';
import Plant from 'svg/Plant';
import Vitamins from 'svg/Vitamins';

import {
  StyledCare,
  StyledSwiper,
  StyledImg,
  StyledContentWrapper,
  StyledHeading,
  StyledText,
  StyledItems,
  StyledItem,
  StyledIcon,
  StyledIconText,
  StyledIconBorder,
} from './CareStyles';

SwiperCore.use([Pagination, Autoplay]);

const Care = ({ data, currentLocale }) => {
  let container = useRef(null);
  let title = useRef(null);
  let text = useRef(null);
  let items = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      careAnimation(container, title, text, items);
    }
  }, []);

  const sliderParams = {
    slidesPerView: 1,
    centeredSlides: true,
    slidesPerGroup: 1,
    speed: 800,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      500: {
        pagination: {
          dynamicBullets: data.images[0].length > 10 && true,
        },
      },
      992: {
        pagination: {
          dynamicBullets: data.images[0].length > 15 && true,
        },
      },
      1200: {
        pagination: {
          dynamicBullets: data.images[0].length > 13 && true,
        },
      },
      1920: {
        pagination: {
          dynamicBullets: data.images[0].length > 15 && true,
        },
      },
    },
  };

  return (
    <StyledCare>
      <StyledSwiper>
        <Swiper {...sliderParams}>
          {data.images.map((image, idx) => {
            const gatsbyImage = getImage(image.localFile);
            const alt = image.alternativeText ?? 'care';
            const bgImage = convertToBgImage(gatsbyImage);
            return (
              <StyledImg
                onClick={() => {
                  if (image.caption) {
                    const links = image.caption.split('|');
                    window.open(
                      currentLocale === 'en' && links[1] ? links[1] : links[0],
                      '_blank'
                    );
                  }
                }}
                key={idx}
                tag="div"
                {...bgImage}
                alt={alt}
              />
            );
          })}
        </Swiper>
      </StyledSwiper>

      <StyledContentWrapper
        ref={el => {
          container = el;
        }}
      >
        <StyledHeading
          ref={el => {
            title = el;
          }}
        >
          {parse(data.title)}
        </StyledHeading>
        <StyledText
          ref={el => {
            text = el;
          }}
        >
          {parse(data.text)}
        </StyledText>

        <StyledItems
          ref={el => {
            items = el;
          }}
        >
          <StyledItem>
            <StyledIconBorder>
              <StyledIcon>
                <Plant />
              </StyledIcon>
            </StyledIconBorder>

            <StyledIconText>{data.list_item_1}</StyledIconText>
          </StyledItem>

          <StyledItem>
            <StyledIconBorder>
              <StyledIcon>
                <Vitamins />
              </StyledIcon>
            </StyledIconBorder>

            <StyledIconText>{data.list_item_2}</StyledIconText>
          </StyledItem>

          <StyledItem>
            <StyledIconBorder>
              <StyledIcon>
                <Cream />
              </StyledIcon>
            </StyledIconBorder>

            <StyledIconText>{data.list_item_3}</StyledIconText>
          </StyledItem>

          <StyledItem>
            <StyledIconBorder>
              <StyledIcon>
                <Water />
              </StyledIcon>
            </StyledIconBorder>

            <StyledIconText>{data.list_item_4}</StyledIconText>
          </StyledItem>
        </StyledItems>
      </StyledContentWrapper>
    </StyledCare>
  );
};

export default withLocale(Care);
