import React from 'react';

import { StyledCircle } from './BgCircleStyles';

const BgCircle = () => (
  <StyledCircle
    width="347"
    height="559"
    viewBox="0 0 347 559"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="67.5" cy="279.5" r="279.5" fill="url(#makeupBgCircle)" />
    <defs>
      <linearGradient
        id="makeupBgCircle"
        x1="-116.5"
        y1="-35"
        x2="67.5"
        y2="559"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#503199" />
        <stop offset="1" stopColor="#503199" stopOpacity="0" />
      </linearGradient>
    </defs>
  </StyledCircle>
);

export default BgCircle;
