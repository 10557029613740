import styled, { css } from 'styled-components';
import Img from 'gatsby-image';
import { GatsbyImage } from 'gatsby-plugin-image';

export const StyledSection = styled('div')`
  width: 100%;
  position: relative;
  background: linear-gradient(180deg, #432884 0%, #613cbb 100%);
  overflow: hidden;
  ${({ theme }) => theme.mq.xxxl} {
    min-height: 100vh;
  }
`;

export const StyledContent = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 4.5rem 0;
  position: relative;
  z-index: 2;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.lg} {
    padding: 6rem 0;
  }
  ${({ theme }) => theme.mq.xl} {
    padding: 10rem 0;
  }
  ${({ theme }) => theme.mq.xxl} {
    padding: 12rem 0;
  }
`;

export const StyledTitle = styled('h2')`
  align-self: center;
  font-weight: 600;
  font-size: 4.8rem;
  line-height: 8.4rem;
  ${({ theme }) => theme.mq.lg} {
    text-transform: none;
    font-size: 6.4rem;
    line-height: 6.4rem;
  }
  * {
    margin: 0;
  }
`;

export const StyledSubtitle = styled('div')`
  text-align: center;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 3.2rem;
  padding: 0 1.5rem;
  max-width: 580px;
  margin-bottom: 0;
  ${({ theme }) => theme.mq.lg} {
    margin-top: 3.8rem;
    padding: 0;
    font-size: 2.4rem;
    line-height: 3.8rem;
    max-width: 558px;
  }
`;

export const StyledProductWrapper = styled('div')`
  padding-bottom: 6rem;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  grid-gap: 7rem;
  ${({ theme }) => theme.mq.lg} {
    grid-gap: 3rem;
    padding: 0 4rem 6rem;
    grid-template-columns: 1fr 2fr 1fr;
  }
  ${({ theme }) => theme.mq.xl} {
    padding: 0 4rem 10rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    padding: 0 6rem 12rem;
  }
`;

export const StyledFunctionColFirst = styled('div')`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  grid-gap: 2rem;
  order: 3;
  ${({ theme }) => theme.mq.lg} {
    order: 1;
  }
`;

export const StyledProductBgWrapper = styled('div')`
  display: none;
  ${({ theme }) => theme.mq.lg} {
    position: absolute;
    z-index: 0;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
  }
`;

export const StyledProductBg = styled(Img)`
  width: 100%;
`;

export const StyledProductsColWrapper = styled('div')`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  order: 1;
  position: relative;
  ${({ theme }) => theme.mq.lg} {
    max-width: none;
    order: 2;
  }
`;

export const StyledProductsCol = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const StyledProductInnerWrapper = styled('div')`
  object-fit: contain;
  position: absolute;
  &:first-child {
    position: relative;
  }
  &:nth-child(2) {
    left: 19% !important;
    top: 18% !important;
  }
  &:nth-child(3) {
    left: 61% !important;
    top: 18% !important;
  }
  &:nth-child(4) {
    left: 6% !important;
    top: 26% !important;
  }
  &:nth-child(5) {
    left: 79% !important;
    top: 26% !important;
  }
  ${({ row }) =>
    row === 'last' &&
    css`
      width: 15%;
      z-index: 0;
    `}
  ${({ row }) =>
    row === 'middle' &&
    css`
      width: 20%;
      z-index: 1;
    `}
    ${({ row }) =>
    row === 'first' &&
    css`
      width: 30%;
      z-index: 2;
    `}
`;

export const StyledProduct = styled(GatsbyImage)``;

export const StyledFunctionColSecond = styled('div')`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  grid-gap: 7rem;
  order: 2;
  ${({ theme }) => theme.mq.lg} {
    order: 3;
    grid-gap: 6rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    order: 3;
    grid-gap: 8rem;
  }
`;

export const StyledSectionBgWrapper = styled('div')`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0%;
  z-index: 0;
  ${({ theme }) => theme.mq.lg} {
    width: 60%;
    top: 50%;
    transform: translateY(-50%);
  }
  ${({ theme }) => theme.mq.xl} {
    width: 40%;
  }
`;

export const StyledPalmBgDesktop = styled(Img)`
  display: none;
  ${({ theme }) => theme.mq.lg} {
    display: block;
    width: 100%;
  }
`;

export const StyledPalmBgMobile = styled(Img)`
  display: block;
  width: 100%;
  ${({ theme }) => theme.mq.lg} {
    display: none;
  }
`;
