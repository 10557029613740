import React from 'react';
import Typewriter from 'typewriter-effect';
import { getImage } from 'gatsby-plugin-image';
import withLocale from 'hoc/withLocale';
import FlyingProducts from 'components/DotOverI/FlyingProducts';
import parse from 'html-react-parser';

import {
  StyledSection,
  StyledHeading,
  StyledHeadingWrapper,
} from './DotOverIStyles';

const DotOverI = ({ data }) => {
  const text = parse(data.text).props.children;

  return (
    <StyledSection>
      {data.images.slice(0, 4).map((image, index) => {
        const gatsbyImage = getImage(image.localFile);
        const alt = image.alternativeText ?? 'dot_over_i';
        return (
          <FlyingProducts
            key={index}
            image={gatsbyImage}
            imageAlt={alt}
            counter={index}
          />
        );
      })}

      <StyledHeadingWrapper>
        <StyledHeading>
          <Typewriter
            options={{
              strings: text,
              autoStart: true,
              loop: true,
              delay: 70,
              pauseFor: 5000,
              wrapperClassName: 'typing-text',
            }}
          />
        </StyledHeading>
      </StyledHeadingWrapper>
    </StyledSection>
  );
};

export default withLocale(DotOverI);
