import React from 'react';

const Brush = () => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75618 27.9184L17.6502 17.0245L24.9764 24.3507L14.0824 35.2446C12.0593 37.2677 8.77925 37.2677 6.75618 35.2446V35.2446C4.7331 33.2216 4.7331 29.9415 6.75618 27.9184V27.9184Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.9799 22.551L24.9741 24.3525L17.6473 17.0257L19.4488 8.01995C19.8077 6.22249 21.5087 5.01882 23.3232 5.27831L23.5088 5.30457C26.8372 5.7808 29.9215 7.32327 32.2992 9.70065V9.70065C34.6766 12.0783 36.219 15.1626 36.6953 18.4911L36.7215 18.6766C36.981 20.4911 35.7773 22.1921 33.9799 22.551V22.551Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4956 31.5044L13.9971 28.0029"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Brush;
