import React, { useRef, useEffect } from 'react';
import Parallax from 'parallax-js';
import { isBrowser } from 'react-device-detect';
import { dotAnimation } from 'theme/Animations';

import BackgroundShapes1 from 'components/DotOverI/bg/BackgroundShapes1';
import BackgroundShapes2 from 'components/DotOverI/bg/BackgroundShapes2';
import BackgroundShapes3 from 'components/DotOverI/bg/BackgroundShapes3';
import BackgroundShapes4 from 'components/DotOverI/bg/BackgroundShapes4';

import {
  StyledProduct,
  StyledWrapper,
  StyledInnerWrapper,
  StyledImgWrapper,
  StyledImg,
} from './FlyingProductsStyles';

const FlyingProducts = ({ image, imageAlt, counter }) => {
  const scene = useRef(null);
  let container = useRef(null);
  let product = useRef(null);
  useEffect(() => {
    if (isBrowser) {
      const parallaxInstance = new Parallax(scene.current);
      parallaxInstance.enable();
      dotAnimation(container, product);

      return () => parallaxInstance.disable();
    }
  }, []);

  const renderBackground = data => {
    switch (data) {
      case 0:
        return <BackgroundShapes1 />;
      case 1:
        return <BackgroundShapes2 />;
      case 2:
        return <BackgroundShapes3 />;
      case 3:
        return <BackgroundShapes4 />;
      default:
        return null;
    }
  };

  return (
    <StyledProduct
      ref={el => {
        container = el;
      }}
    >
      <StyledWrapper
        ref={el => {
          product = el;
        }}
      >
        {renderBackground(counter)}

        <StyledInnerWrapper ref={scene}>
          <StyledImgWrapper data-depth={(Math.random() - 0.5) / 2}>
            <StyledImg image={image} alt={imageAlt} />
          </StyledImgWrapper>
        </StyledInnerWrapper>
      </StyledWrapper>
    </StyledProduct>
  );
};

export default FlyingProducts;
