import styled from "styled-components";

export const StyledFace = styled('div')`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 270px;
    height: 300px;
    ${({ theme }) => theme.mq.lg} {
        width: 350px;
        height: 350px;
    }
    img {
        object-fit: contain;
        position: absolute;
        &:nth-of-type(1) {
            z-index: 5;
            top: 50%;
            left: 50%;
            width: 184px;
            height: 262px;
            transform: translate(-50%, -50%);
            ${({ theme }) => theme.mq.lg} {
                width: 250px;
                height: 300px;
            }
            ${({ theme }) => theme.mq.xxxl} {
                width: 347px;
                height: 370px;
            }
        }
        &:nth-of-type(2) {
            top: 16% !important;
            left: 3% !important;
            width: 48px;
            height: 60px;
            ${({ theme }) => theme.mq.lg} {
                width: 62px;
                height: 77px;
            }
            ${({ theme }) => theme.mq.xxxl} {
                top: 3% !important;
                left: -1% !important;
                width: 73px;
                height: 97px;
            }
        }
        &:nth-of-type(3) {
            top: -4% !important;
            left: 31% !important;
            width: 32px;
            height: 46px;
            ${({ theme }) => theme.mq.lg} {
                width: 39px;
                height: 39px;
            }
            ${({ theme }) => theme.mq.xxxl} {
                width: 47px;
                height: 47px;
                top: -13% !important;
            }
        }
        &:nth-of-type(4) {
            top: 38% !important;
            left: 14% !important;
            width: 65px;
            height: 76px;
            ${({ theme }) => theme.mq.lg} {
                width: 80px;
                height: 80px;
            }
            ${({ theme }) => theme.mq.xxxl} {
                width: 90px;
                height: 90px;
                left: 11% !important;
            }
        }
        &:nth-of-type(5) {
            top: 15% !important;
            left: 40% !important;
            width: 140px;
            height: 140px;
            ${({ theme }) => theme.mq.lg} {
                width: 175px;
                height: 175px;
            }
            ${({ theme }) => theme.mq.xxxl} {
                top: 10% !important;
                width: 200px;
                height: 200px;
            }
        }
    }
`
