import styled from 'styled-components';

export const StyledSectionWrapper = styled('div')`
  position: relative;
  overflow: hidden;
  width: 100%;
  &:before {
    content: '';
    height: 10px;
    width: 100%;
    position: absolute;
    bottom: -5px;
    left: 0;
    background-color: #3bb273;
  }
`;

export const StyledSection = styled('div')`
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  background: linear-gradient(
    180deg,
    rgba(59, 178, 115, 0.3) 0%,
    #3bb273 50.83%,
    #3bb273 100%
  );
`;

export const StyledContent = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 4.5rem;
  position: relative;
  z-index: 2;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.lg} {
    padding: 6rem 0 2rem;
  }
  ${({ theme }) => theme.mq.xl} {
    padding: 10rem 0 4rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    padding: 12rem 0 5rem;
  }
`;

export const StyledTitle = styled('h2')`
  align-self: center;
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 4.8rem;
  padding: 0 1.5rem;
  text-align: center;
  ${({ theme }) => theme.mq.lg} {
    font-size: 6.8rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 8.2rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    font-size: 11.2rem;
  }
`;

export const StyledProductWrapper = styled('div')`
  padding-bottom: 6rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 570px auto auto;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  max-width: 1800px;
  margin: 0 auto;
  ${({ theme }) => theme.mq.smm} {
    grid-template-rows: 670px auto auto;
  }
  ${({ theme }) => theme.mq.lg} {
    grid-gap: 3rem;
    padding: 0 4rem 6rem;
    align-items: flex-end;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
  }
  ${({ theme }) => theme.mq.xl} {
    padding: 0 4rem 10rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    padding: 0 6rem 18rem;
    max-width: 1900px;
  }
  ${({ theme }) => theme.mq.xxl} {
    padding: 0 6rem 25rem;
  }
`;

export const StyledColImage = styled('img')``;

export const StyledColFirst = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  order: 2;
  z-index: 1;
  ${StyledColImage} {
    width: 270px;
    position: absolute;
    left: 89%;
    top: 86%;
    transform: translateX(-46%);
  }
  ${({ theme }) => theme.mq.sm} {
    ${StyledColImage} {
      left: 90%;
    }
  }
  ${({ theme }) => theme.mq.smm} {
    ${StyledColImage} {
      left: 94%;
    }
  }
  ${({ theme }) => theme.mq.md} {
    ${StyledColImage} {
      left: 96%;
      top: 80%;
      width: 300px;
    }
  }
  ${({ theme }) => theme.mq.lg} {
    order: 1;
    align-self: center;
    ${StyledColImage} {
      left: 40%;
      transform: translateX(-50%);
      width: 360px;
    }
  }
  ${({ theme }) => theme.mq.xxl} {
    ${StyledColImage} {
      width: 400px;
      left: 38%;
    }
  }
  ${({ theme }) => theme.mq.xxxl} {
    ${StyledColImage} {
      width: 488px;
      left: 32%;
    }
  }
`;

export const StyledTextWrapper = styled('div')`
  position: relative;
  width: 100%;
`;

export const StyledNumber = styled('span')`
  font-family: 'GraphikLCGSemiBold';
  display: block;
  font-size: 5.9rem;
  line-height: 4.8rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.18;
  padding: 0 2rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  ${({ theme }) => theme.mq.lg} {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-35%, 20%);
    font-size: 8.2rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 10rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    font-size: 11.2rem;
  }
`;

export const StyledText = styled('div')`
  font-size: 1.8rem;
  line-height: 3.2rem;
  color: ${({ theme }) => theme.colors.white};
  padding: 0 2rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
  ${({ theme }) => theme.mq.lg} {
    max-width: 950px;
    font-size: 1.6rem;
    line-height: 2.8rem;
  }
  ${({ theme }) => theme.mq.xl} {
    font-size: 1.8rem;
    line-height: 3.2rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    max-width: 1400px;
    font-size: 2rem;
    line-height: 3.6rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    font-size: 2.4rem;
    line-height: 4.8rem;
  }
`;

export const StyledProductsColWrapper = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  order: 1;
  ${({ theme }) => theme.mq.lg} {
    order: 2;
    height: 675px;
  }
  ${({ theme }) => theme.mq.xxl} {
    height: 875px;
  }
  ${({ theme }) => theme.mq.xxxl} {
    height: 975px;
  }
`;

export const StyledColSecond = styled('div')`
  margin-top: 7rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  order: 3;
  ${StyledColImage} {
    display: none;
  }
  ${({ theme }) => theme.mq.lg} {
    margin-top: 0;
    position: relative;
    ${StyledColImage} {
      display: block;
      width: 84px;
      position: absolute;
      left: 50%;
      bottom: calc(100% + 30px);
      transform: translateX(-50%);
    }
  }
  ${({ theme }) => theme.mq.xxl} {
    ${StyledColImage} {
      width: 100px;
    }
  }
  ${({ theme }) => theme.mq.xxxl} {
    ${StyledColImage} {
      width: 140px;
    }
  }
`;

export const StyledMobileButtonWrapper = styled('div')`
  padding: 0 2rem;
  order: 4;
  margin-top: 5rem;
`;

export const StyledMobileButton = styled('button')`
  display: block;
  padding: 15px;
  text-align: center;
  border-radius: 4px;
  font-size: 1.8rem;
  line-height: 1.8rem;
  width: 100%;
  font-weight: 500;
  background-color: #006f34;
  color: ${({ theme }) => theme.colors.white};
  transition: background-color 0.3s;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  &:hover {
    background-color: ${({ theme }) => theme.colors.purpleDarkest};
  }
  ${({ theme }) => theme.mq.lg} {
    display: none;
  }
`;

export const StyledProduct = styled('img')`
  cursor: pointer;
  position: absolute;
  top: 0;
  display: block;
  max-width: 340px;
  ${({ theme }) => theme.mq.smm} {
    max-width: 400px;
  }
  ${({ theme }) => theme.mq.lg} {
    max-width: none;
    width: 358px;
  }
  ${({ theme }) => theme.mq.xxl} {
    width: 438px;
  }
  ${({ theme }) => theme.mq.xxl} {
    width: 488px;
  }
  ${({ theme }) => theme.mq.xxxl} {
    width: 547px;
  }
`;

export const StyledButton = styled('button')`
  display: none;
  ${({ theme }) => theme.mq.lg} {
    display: block;
    padding: 15px;
    text-align: center;
    border-radius: 4px;
    font-size: 1.6rem;
    line-height: 1.6rem;
    width: 100%;
    font-weight: 500;
    background-color: #006f34;
    color: ${({ theme }) => theme.colors.white};
    transition: background-color 0.3s;
    margin: 0 auto;
    max-width: 288px;
    position: absolute;
    top: 100%;
    &:hover {
      background-color: ${({ theme }) => theme.colors.purpleDarkest};
    }
  }
  ${({ theme }) => theme.mq.xl} {
    font-size: 1.8rem;
    line-height: 1.8rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    top: 101%;
  }
  ${({ theme }) => theme.mq.xxxl} {
    top: 103%;
  }
`;

export const StyledBgItem = styled('img')`
  display: none;
  ${({ theme }) => theme.mq.lg} {
    display: block;
    position: absolute;
    bottom: 0;
    transform: translateY(40%);
    right: 0;
    z-index: 0;
    width: 400px;
  }
  ${({ theme }) => theme.mq.xxl} {
    width: 500px;
  }
  ${({ theme }) => theme.mq.xxxl} {
    width: auto;
  }
`;
