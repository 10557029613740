import styled from "styled-components";
import BackgroundImage from 'gatsby-background-image';

export const StyledBgWrapper = styled('div')`
    position: absolute;
    top: -10%;
    left: 50%;
    transform: translateX(-50%) !important;
    width: 105%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
`;

export const StyledBg = styled(BackgroundImage)`
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom right !important;
    width: 100%;
    height: 100%;
    ${({ theme }) => theme.mq.lg} {
        background-position: 'center' !important;
    }
`;
