import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Element } from 'react-scroll';
import { injectIntl } from 'gatsby-plugin-intl';
import withLocale from 'hoc/withLocale';
import SEO from 'components/seo/seo';
import Layout from 'layouts/Layout';
import Header from 'sections/Header/Header';
import AboutUs from 'sections/AboutUs/AboutUs';
import Clean from 'sections/Clean/Clean';
import CleanSlider from 'sections/CleanSlider/CleanSlider';
import TitleSlider from 'sections/TitleSlider/TitleSlider';
import Ritual from 'sections/Ritual/Ritual';
import Moisturize from 'sections/Moisturize/Moisturize';
import Care from 'sections/Care/Care';
import Suggestions from 'sections/Suggestions/Suggestions';
import Masks from 'sections/Masks/Masks';
import Gel from 'sections/Gel/Gel';
import Benefits from 'sections/Benefits/Benefits';
import Film from 'sections/Film/Film';
import Aloes from 'sections/Aloes/Aloes';
import DotOverI from 'sections/DotOverI/DotOverI';
import Makeup from 'sections/Makeup/Makeup';
import BB from 'sections/BB/BB';
import Poland from 'sections/Poland/Poland';
import Form from 'sections/Form/Form';
import Footer from 'components/Footer/Footer';
import { TemplateEnum } from '../enums/templates';

const Scroll = require('react-scroll');

const { scroller } = Scroll;

const IndexPage = props => {
  const { currentLocale } = props;
  const data = useStaticQuery(
    graphql`
      query {
        metaPl: strapiHomepageMetatags(locale: { eq: "pl" }) {
          SEO {
            meta_title
            meta_description
            image_alt
            meta_image {
              localFile {
                url
              }
            }
          }
        }
        metaEn: strapiHomepageMetatags(locale: { eq: "en" }) {
          SEO {
            meta_title
            meta_description
            image_alt
            meta_image {
              localFile {
                url
              }
            }
          }
        }
        orderPl: allStrapiSectionsOrder(filter: { locale: { eq: "pl" } }) {
          nodes {
            order {
              all_section {
                id
                name
                hash
              }
            }
          }
        }
        orderEn: allStrapiSectionsOrder(filter: { locale: { eq: "en" } }) {
          nodes {
            order {
              all_section {
                id
                name
                hash
              }
            }
          }
        }
        dataPl: allStrapiAllSectionsExt(filter: { locale: { eq: "pl" } }) {
          edges {
            node {
              strapiId
              sectionsJSON {
                _0 {
                  title
                  title2
                  text
                  text2
                  text3
                  text_left
                  text_right
                  link
                  list {
                    items
                  }
                  list_item_1
                  list_item_2
                  list_item_3
                  list_item_4
                  color
                  _xtypename
                  image {
                    alternativeText
                    caption
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  image2 {
                    alternativeText
                    caption
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  images {
                    alternativeText
                    caption
                    localFile {
                      publicURL
                      childImageSharp {
                        gatsbyImageData
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  background {
                    alternativeText
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  backgrounds {
                    alternativeText
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  film {
                    url
                  }
                }
              }
            }
          }
        }
        dataEn: allStrapiAllSectionsExt(filter: { locale: { eq: "en" } }) {
          edges {
            node {
              strapiId
              sectionsJSON {
                _0 {
                  title
                  title2
                  text
                  text2
                  text3
                  text_left
                  text_right
                  link
                  list {
                    items
                  }
                  list_item_1
                  list_item_2
                  list_item_3
                  list_item_4
                  color
                  _xtypename
                  image {
                    alternativeText
                    caption
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  image2 {
                    alternativeText
                    caption
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  images {
                    alternativeText
                    caption
                    localFile {
                      publicURL
                      childImageSharp {
                        gatsbyImageData
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  background {
                    alternativeText
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  backgrounds {
                    alternativeText
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  film {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  let meta;
  let order;
  let allStrapiData;
  if (currentLocale === 'pl') {
    meta = data.metaPl;
    order = data.orderPl.nodes[0].order;
    allStrapiData = data.dataPl.edges;
  } else if (currentLocale === 'en') {
    meta = data.metaEn;
    order = data.orderEn.nodes[0].order;
    allStrapiData = data.dataEn.edges;
  }

  const {
    meta_title: metaTitle,
    meta_description: metaDescription,
    image_alt: imgAlt,
    meta_image: {
      localFile: { url },
    },
  } = meta.SEO;

  const getSection = () =>
    order.map((item, idx) => {
      if (!item || !Object.keys(item).length) {
        return <></>;
      }

      let sectionsData = allStrapiData.find(
        strapiData => strapiData.node.strapiId === item.all_section.id
      );

      if (!sectionsData) {
        return <></>;
      }

      sectionsData = sectionsData.node.sectionsJSON._0;

      const type = sectionsData._xtypename;
      const { name } = item.all_section;
      const hash = item.all_section.hash
        ? `#${item.all_section.hash}`
        : undefined;

      if (type === TemplateEnum.AboutUs) {
        return (
          <Element name={hash ?? name} key={idx}>
            <AboutUs data={sectionsData} currentLocale={currentLocale} />
          </Element>
        );
      }
      if (type === TemplateEnum.TitleSlider) {
        return (
          <Element name={hash ?? name} key={idx}>
            <TitleSlider data={sectionsData} currentLocale={currentLocale} />
          </Element>
        );
      }
      if (type === TemplateEnum.TextSingleImageLeftAndRight) {
        return (
          <Element name={hash ?? name} key={idx}>
            <Ritual data={sectionsData} />
          </Element>
        );
      }
      if (type === TemplateEnum.TextImagesWithBackgrounds) {
        return (
          <Element name={hash ?? name} key={idx}>
            <Clean data={sectionsData} currentLocale={currentLocale} />
          </Element>
        );
      }
      if (type === TemplateEnum.TitleTextSliderRight) {
        return (
          <Element name={hash ?? name} key={idx}>
            <CleanSlider data={sectionsData} currentLocale={currentLocale} />
          </Element>
        );
      }
      if (type === TemplateEnum.TitleTextBigImageLeft) {
        return (
          <Element name={hash ?? name} key={idx}>
            <Moisturize data={sectionsData} currentLocale={currentLocale} />
          </Element>
        );
      }
      if (type === TemplateEnum.TitleTextListSlider) {
        return (
          <Element name={hash ?? name} key={idx}>
            <Care data={sectionsData} currentLocale={currentLocale} />
          </Element>
        );
      }
      if (type === TemplateEnum.TitleTextImagesGrid) {
        return (
          <Element name={hash ?? name} key={idx}>
            <Suggestions data={sectionsData} currentLocale={currentLocale} />
          </Element>
        );
      }
      if (type === TemplateEnum.TitleSliderTitle2Content) {
        return (
          <Element name={hash ?? name} key={idx}>
            <Masks data={sectionsData} currentLocale={currentLocale} />
          </Element>
        );
      }
      if (type === TemplateEnum.TitleTextLeftTextRight) {
        return (
          <Element name={hash ?? name} key={idx}>
            <Gel data={sectionsData} />
          </Element>
        );
      }
      if (type === TemplateEnum.Film) {
        return (
          <Element name={hash ?? name} key={idx}>
            <Film data={sectionsData} />
          </Element>
        );
      }
      if (type === TemplateEnum.ListWithBackground) {
        return (
          <Element name={hash ?? name} key={idx}>
            <Aloes data={sectionsData} />
          </Element>
        );
      }
      if (type === TemplateEnum.TitleListWithBackground) {
        return (
          <Element name={hash ?? name} key={idx}>
            <Benefits data={sectionsData} />
          </Element>
        );
      }
      if (type === TemplateEnum.TypingText) {
        return (
          <Element name={hash ?? name} key={idx}>
            <DotOverI data={sectionsData} />
          </Element>
        );
      }
      if (type === TemplateEnum.TitleTextSlider) {
        return (
          <Element name={hash ?? name} key={idx}>
            <Makeup data={sectionsData} currentLocale={currentLocale} />
          </Element>
        );
      }
      if (type === TemplateEnum.TitleDescriptionImagesCenterList) {
        return (
          <Element name={hash ?? name} key={idx}>
            <BB data={sectionsData} />
          </Element>
        );
      }
      if (type === TemplateEnum.TitleTextMap) {
        return (
          <Element name={hash ?? name} key={idx}>
            <Poland data={sectionsData} currentLocale={currentLocale} />
          </Element>
        );
      }
      if (type === TemplateEnum.ContactForm) {
        return (
          <Element name={hash ?? name} key={idx}>
            <Form data={sectionsData} />
          </Element>
        );
      }

      return <></>;
    });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { hash } = props.location;

      if (hash) {
        scroller.scrollTo(hash, {
          duration: 1000,
          delay: 1000,
          smooth: true,
        });
      }
    }
  }, []);

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription}
        image={url}
        imgAlt={imgAlt}
      />
      <Element name="Home">
        <Header />
      </Element>
      {getSection()}
      <Element name="navigation_footer">
        <Footer />
      </Element>
    </Layout>
  );
};

export default injectIntl(withLocale(IndexPage));
