import React, { useRef, useEffect } from 'react';
import { isBrowser } from 'react-device-detect';
import { ritualAnimation } from 'theme/Animations';
import Step1 from 'components/Ritual/Step1';
import Step2 from 'components/Ritual/Step2';
import parse from 'html-react-parser';
import {
  StyledRitualWrapper,
  StyledItemWrapper,
  StyledImageWrapper,
  StyledTextWrapper,
  StyledText,
} from './RitualStyles';

const Ritual = ({ data }) => {
  let item1 = useRef(null);
  let item2 = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      ritualAnimation(item1, item2);
    }
  }, []);

  return (
    <StyledRitualWrapper>
      <StyledItemWrapper
        ref={el => {
          item1 = el;
        }}
      >
        <StyledImageWrapper>
          <Step1 />
        </StyledImageWrapper>

        <StyledTextWrapper>
          <StyledText>{parse(data.text_right)}</StyledText>
        </StyledTextWrapper>
      </StyledItemWrapper>

      <StyledItemWrapper
        ref={el => {
          item2 = el;
        }}
      >
        <StyledTextWrapper>
          <StyledText>{parse(data.text_left)}</StyledText>
        </StyledTextWrapper>

        <StyledImageWrapper>
          <Step2 />
        </StyledImageWrapper>
      </StyledItemWrapper>
    </StyledRitualWrapper>
  );
};

export default Ritual;
