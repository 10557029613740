import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

export const StyledSectionWrapper = styled('div')`
  width: 100%;
  position: relative;
  background: linear-gradient(180deg, #553999 -100%, #432883 100%);
  padding-bottom: 6rem;
  ${({ theme }) => theme.mq.md} {
    padding-bottom: 8rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    padding-bottom: 12rem;
  }
`;

export const StyledContent = styled('div')`
  width: 100%;
  max-width: 600px;
  padding: 8rem 0 5rem;
  margin: 0 auto;
  ${({ theme }) => theme.mq.md} {
    padding: 10rem 0 6rem;
  }
  ${({ theme }) => theme.mq.lg} {
    max-width: 950px;
  }
  ${({ theme }) => theme.mq.xxl} {
    max-width: 1400px;
    padding: 16rem 0 10rem;
  }
`;

export const StyledHeading = styled('h2')`
  font-size: 3.5rem;
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 3.5rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  ${({ theme }) => theme.mq.md} {
    font-size: 4.3rem;
    line-height: 6.4rem;
    text-transform: none;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 4.5rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 5.5rem;
  }
`;

export const StyledSwiper = styled('div')`
  width: 100vw;
  position: relative;
  z-index: 9;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 60px;
    z-index: 9;
    ${({ theme }) => theme.mq.lg} {
      width: 80px;
    }
    ${({ theme }) => theme.mq.xxxl} {
      width: 120px;
    }
  }
  &::before {
    left: 0;
    background: linear-gradient(
      90deg,
      #432883 -36.75%,
      rgba(67, 40, 131, 0) 89.46%
    );
  }
  &::after {
    right: 0;
    background: linear-gradient(
      -90deg,
      #432883 -36.75%,
      rgba(67, 40, 131, 0) 89.46%
    );
  }
  .slider-arrow {
    display: none;
    ${({ theme }) => theme.mq.md} {
      box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 100%;
      height: 45px;
      width: 45px;
      background-color: ${({ theme }) => theme.colors.white};
      z-index: 10;
      color: ${({ theme }) => theme.colors.black};
      &:after {
        font-size: 2rem;
      }
      ${({ theme }) => theme.mq.lg} {
        height: 56px;
        width: 56px;
      }
    }
  }
  .swiper-button-prev {
    left: 60px;
    ${({ theme }) => theme.mq.lg} {
      left: 80px;
    }
    ${({ theme }) => theme.mq.xxxl} {
      left: 120px;
    }
  }
  .swiper-button-next {
    right: 60px;
    ${({ theme }) => theme.mq.lg} {
      right: 80px;
    }
    ${({ theme }) => theme.mq.xxxl} {
      right: 120px;
    }
  }
  .swiper-pagination {
    ${({ theme }) => theme.mq.md} {
      display: none;
    }
  }
  .swiper-pagination-bullet {
    width: 10px !important;
    height: 10px !important;
    transform: scale(1) !important;
    transition: 0.3s;
    &-active {
      background-color: hsla(66, 99%, 66%, 1) !important;
    }
  }
`;

export const StyledImg = styled(BackgroundImage)`
  cursor: pointer;
  overflow: hidden;
  height: auto;
  width: auto;
  transition: transform 0.3s;
  background-color: transparent;
  display: block;
  transform: scale(0.9) !important;
  &.swiper-slide-active {
    transform: scale(1) !important;
  }
  ${({ theme }) => theme.mq.md} {
    height: 32vw;
    width: 32vw;
  }
  ${({ theme }) => theme.mq.lg} {
    height: 27vw;
    width: 27vw;
  }
  ${({ theme }) => theme.mq.xxxl} {
    height: 23vw;
    width: 23vw;
  }
`;
