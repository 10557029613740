import React from 'react';

const IconSun = () => (
  <svg
    width="132"
    height="132"
    viewBox="0 0 132 132"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M88.3917 43.6089C100.758 55.9755 100.758 76.0258 88.3917 88.3924C76.0251 100.759 55.9748 100.759 43.6082 88.3924C31.2416 76.0258 31.2416 55.9755 43.6082 43.6089C55.9748 31.2423 76.0251 31.2423 88.3917 43.6089"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66 15.3337V2.66699"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66 129.334V116.667"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M106.28 25.7207L110.776 21.224"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.2235 110.776L25.7202 106.28"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M116.667 66.0006H129.333"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.66667 66.0006H15.3333"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M106.28 106.28L110.776 110.776"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.2235 21.224L25.7202 25.7207"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconSun;
