import styled from 'styled-components';
import Slider from 'react-slick';

export const SliderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  order: 1;
  ${({ theme }) => theme.mq.lg} {
    margin-top: -3rem;
    order: 2;
  }
  ${({ theme }) => theme.mq.xxl} {
    margin-top: 0;
  }
  ${({ theme }) => theme.mq.xxxl} {
    margin-top: -4rem;
  }
`;

export const StyledSlider = styled(Slider)`
  width: 100vw;
  .slick-dots {
    bottom: -25px;
    ${({ theme }) => theme.mq.smm} {
      bottom: 30px;
    }
    ${({ theme }) => theme.mq.lg} {
      bottom: 0px;
    }
    ${({ theme }) => theme.mq.xxxl} {
      bottom: 5rem;
    }
  }
  .ft-slick__dots--custom {
    width: 10px;
    height: 10px;
    background-color: #fff;
    opacity: 0.5;
    transition: 0.2s;
    border-radius: 100%;
  }
  .slick-active {
    .ft-slick__dots--custom {
      opacity: 1;
      background-color: hsla(66, 99%, 66%, 1) !important;
    }
  }
  ${({ theme }) => theme.mq.smm} {
    width: 500px;
  }
  ${({ theme }) => theme.mq.md} {
    width: 600px;
  }
  ${({ theme }) => theme.mq.lg} {
    width: 450px;
    .ft-slick__dots--custom {
      width: 8px;
      height: 8px;
    }
    .slick-active {
      .ft-slick__dots--custom {
        transform: scale(1.64);
        background-color: #fff !important;
      }
    }
  }
  ${({ theme }) => theme.mq.xxl} {
    width: 600px;
  }
  ${({ theme }) => theme.mq.xxxl} {
    width: 750px;
  }
`;

export const StyledMakeup = styled('div')`
  background: linear-gradient(180deg, #432884 0%, #613cbb 100%);
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 5rem 0 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.mq.lg} {
    padding: 6rem 0 8rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    padding: 10rem 0 9rem;
  }
`;

export const StyledContainer = styled('div')`
  position: relative;
  max-width: 600px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem;
  justify-content: center;
  z-index: 1;
  align-items: center;
  ${({ theme }) => theme.mq.smm} {
    grid-gap: 0rem;
  }
  ${({ theme }) => theme.mq.lg} {
    max-width: 950px;
    grid-template-columns: 1fr 400px;
  }
  ${({ theme }) => theme.mq.xxl} {
    max-width: 1200px;
    grid-template-columns: 1fr 550px;
    grid-gap: 0rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    max-width: 1600px;
    grid-template-columns: 1fr 700px;
    align-items: flex-start;
  }
`;

export const StyledContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0 2.5rem;
  order: 2;
  ${({ theme }) => theme.mq.lg} {
    order: 1;
    padding-right: 4rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    padding-right: 7rem;
  }
`;

export const StyledTitle = styled('h2')`
  display: none;
  ${({ theme }) => theme.mq.lg} {
    text-align: left;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
    font-size: 4.2rem;
    line-height: 4.5rem;
    display: block;
    margin-bottom: 3rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 5.4rem;
    line-height: 7.4rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    font-size: 6.4rem;
    line-height: 8.4rem;
  }
`;

export const StyledTitleMobile = styled('h2')`
  font-size: 3.6rem;
  line-height: 3.6rem;
  display: block;
  text-align: center;
  font-weight: 700;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.smm} {
    margin-bottom: 4rem;
  }
  ${({ theme }) => theme.mq.lg} {
    display: none;
  }
`;

export const StyledText = styled('div')`
  font-size: 1.8rem;
  line-height: 3.6rem;
  text-align: left;
  margin-top: 0;
  margin-bottom: 7rem;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.lg} {
    margin: 0 0 5rem 0;
  }
  ${({ theme }) => theme.mq.xl} {
    font-size: 1.9rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 2rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    font-size: 2.4rem;
    line-height: 4.8rem;
    margin: 0 0 7rem 0;
  }
`;

export const StyledButton = styled('button')`
  display: block;
  text-align: center;
  border-radius: 4px;
  padding: 18px 50px;
  width: 100%;
  font-size: 1.8rem;
  line-height: 1.8rem;
  background-color: ${({ theme }) => theme.colors.pink};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  transition: 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.purpleDarkest};
    color: ${({ theme }) => theme.colors.white};
  }
  ${({ theme }) => theme.mq.lg} {
    width: auto;
    min-width: 288px;
  }
`;

export const StyledSliderWrapper = styled('div')`
  max-width: 300px;
  position: relative;
  z-index: 9;
  margin: 0 auto;
  ${({ theme }) => theme.mq.xxxl} {
    max-width: 750px;
  }
`;

export const StyledImg = styled('img')`
  max-width: 100%;
`;

export const StyledInnerWrapper = styled('div')`
  position: relative;
`;

export const StyledParallaxWrapper = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 270px;
  height: 300px;
  ${({ theme }) => theme.mq.lg} {
    width: 350px;
    height: 350px;
  }
  svg {
    object-fit: contain;
    position: absolute;
  }
`;

export const StyledImgWrapper = styled.div`
  cursor: pointer;
`;
