import styled from "styled-components";

export const StyledIcon = styled('div')`
    border-radius: 100%;
    width: 54px;
    height: 54px;
    min-width: 54px;
    min-height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-color: ${({theme}) => theme.colors.pink };
    svg{
        max-width: 100%;
        max-height: 100%;
    }
    ${({ theme }) => theme.mq.lg} {
        width: 60px;
        height: 60px;
        min-width: 60px;
        min-height: 60px;
    }
    ${({ theme }) => theme.mq.xxxl} {
        width: 71px;
        height: 71px;
        min-width: 71px;
        min-height: 71px;
    }
`;
