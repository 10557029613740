import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby-plugin-intl';

export const StyledAboutUs = styled('div')`
  width: 100%;
  /* min-height: 100vh; */
  position: relative;
`;

export const StyledContainer = styled('div')`
  max-width: 600px;
  margin: 0 auto;
  ${({ theme }) => theme.mq.lg} {
    max-width: 950px;
  }
  ${({ theme }) => theme.mq.xxl} {
    max-width: 1400px;
  }
`;

export const StyledWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 3.8rem 2.5rem 6rem;
  overflow: hidden;
  ${({ theme }) => theme.mq.sm} {
    padding: 3.8rem 2.5rem 6rem;
  }
  ${({ theme }) => theme.mq.smm} {
    padding: 3.8rem 2.5rem 10rem;
  }
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
    padding: 12rem 2.5rem 8rem;
  }
  ${({ theme }) => theme.mq.xl} {
    padding: 12rem 2.5rem 11rem;
  }
`;

export const StyledAloeImage = styled('div')`
  position: absolute;
  left: 0;
  bottom: -70px;
  z-index: 1;
  ${({ theme }) => theme.mq.lg} {
    bottom: -120px;
    display: none;
  }
  ${({ theme }) => theme.mq.xl} {
    display: block;
  }
  ${({ theme }) => theme.mq.xxl} {
    bottom: -140px;
  }
`;

export const StyledAloeImageImg = styled(GatsbyImage)`
  width: 180px;
  ${({ theme }) => theme.mq.md} {
    width: 200px;
  }
  ${({ theme }) => theme.mq.lg} {
    width: 280px;
  }
  ${({ theme }) => theme.mq.xxxl} {
    width: 400px;
  }
`;

export const StyledBubbleItem = styled('div')`
  &:nth-of-type(1) {
    position: absolute;
    width: 150px;
    height: 150px;
    left: -70px;
    bottom: 40px;
    border-radius: 50%;
    background: rgb(214, 203, 237);
    background: linear-gradient(
      180deg,
      rgba(214, 203, 237, 1) 80%,
      rgba(9, 9, 121, 0) 100%
    );
    ${({ theme }) => theme.mq.md} {
      width: 180px;
      height: 180px;
    }
    ${({ theme }) => theme.mq.xl} {
      width: 260px;
      height: 260px;
      left: -150px;
      bottom: 50px;
    }
    ${({ theme }) => theme.mq.xxxl} {
      width: 320px;
      height: 320px;
      bottom: 100px;
    }
  }
  &:nth-of-type(2) {
    display: none;
    content: '';
    position: absolute;
    width: 58px;
    height: 58px;
    left: 220px;
    bottom: 150px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.purpleLight};
    ${({ theme }) => theme.mq.lg} {
      display: block;
    }
    ${({ theme }) => theme.mq.xxxl} {
      width: 88px;
      height: 88px;
      left: 300px;
      bottom: 230px;
    }
  }
  &:nth-of-type(3) {
    display: none;
    content: '';
    position: absolute;
    width: 26px;
    height: 26px;
    left: 180px;
    bottom: 400px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.purpleLight};
    ${({ theme }) => theme.mq.xxxl} {
      display: block;
    }
  }
`;

export const StyledContent = styled('div')`
  color: ${({ theme }) => theme.colors.purple};
  width: 100%;
  ${({ theme }) => theme.mq.lg} {
    width: 50%;
    padding-right: 3rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    padding-right: 10rem;
  }
`;

export const StyledTitle = styled('h2')`
  font-size: 3.2rem;
  margin: 0 0 2rem;
  ${({ theme }) => theme.mq.lg} {
    font-size: 7.2rem;
    margin: 0 0 3rem;
  }
`;

export const StyledParagraph = styled('div')`
  font-size: 1.6rem;
  line-height: 3.2rem;
  margin: 0 0 3rem;
  ${({ theme }) => theme.mq.lg} {
    font-size: 2rem;
    line-height: 3.3rem;
    margin: 0 0 4rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 2.4rem;
    line-height: 3.8rem;
  }
`;

export const StyledImages = styled('div')`
  width: 100%;
  height: 300px;
  position: relative;
  margin-top: 30px;
  ${({ theme }) => theme.mq.lg} {
    margin-top: 20px;
    width: 50%;
    height: 500px;
  }
  ${({ theme }) => theme.mq.xxl} {
    margin-top: 0;
    height: 800px;
  }
`;

export const StyledImagesInner = styled('div')`
  display: block;
`;

export const StyledImage = styled('div')`
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

export const StyledImageImg = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  cursor: pointer;
`;

export const StyledBtn = styled(Link)`
  width: auto;
  padding: 15px;
  text-align: center;
  border-radius: 4px;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 500;
  background-color: ${({ theme }) => theme.colors.purple};
  color: ${({ theme }) => theme.colors.white};
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.purpleLight};
  }
`;
