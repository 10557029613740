import styled from 'styled-components';
import Img from 'gatsby-image';

export const StyledSection = styled('div')`
  position: relative;
  overflow: hidden;
  background-color: #fff;
  ${({ theme }) => theme.mq.xxxl} {
    min-height: 100vh;
  }
`;

export const StyledBgwrapper = styled('div')`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  opacity: 0.5;
  ${({ theme }) => theme.mq.xxxl} {
    opacity: 0.8;
  }
`;

export const StyledImg = styled(Img)`
  min-height: 100%;
  min-width: 100%;
`;

export const StyledContentWrapper = styled('div')`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
  padding: 6rem 0rem;
  ${({ theme }) => theme.mq.md} {
    padding: 6rem 0 10rem;
  }
  ${({ theme }) => theme.mq.lg} {
    max-width: 950px;
    padding: 12rem 0 3rem;
  }
  ${({ theme }) => theme.mq.xl} {
    padding: 14rem 0 3rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    max-width: 1400px;
    padding: 17rem 0 3rem;
  }
`;

export const StyledHeading = styled('h2')`
  font-size: 2.4rem;
  line-height: 4.2rem;
  font-weight: 700;
  text-align: center;
  color: ${({ theme }) => theme.colors.purple};
  margin-bottom: 6rem;
  padding: 0 1rem;
  span {
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    color: ${({ theme }) => theme.colors.pink};
  }
  ${({ theme }) => theme.mq.md} {
    font-size: 3.8rem;
    line-height: 5.2rem;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 4.2rem;
    line-height: 6.8rem;
    text-align: left;
    margin-bottom: 7rem;
    padding: 0 2.5rem;
  }
  ${({ theme }) => theme.mq.xl} {
    margin-bottom: 9rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    margin-bottom: 12rem;
    font-size: 4.8rem;
    line-height: 7.2rem;
  }
`;

export const StyledItemsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 2.5rem 5rem;
  ${({ theme }) => theme.mq.lg} {
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(2, auto);
    flex-wrap: wrap;
    grid-row-gap: 4rem;
    grid-column-gap: 8rem;
    padding: 0 2.5rem 10rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    grid-column-gap: 16rem;
  }
`;

export const StyledItem = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 3rem;
  &:last-child {
    margin-bottom: 0;
  }
  ${({ theme }) => theme.mq.lg} {
    margin-bottom: 0;
  }
`;

export const StyledLabel = styled('span')`
  margin-left: 1.5rem;
  font-size: 1.8rem;
  line-height: 3.2rem;
  text-align: left;
  color: ${({ theme }) => theme.colors.purple};
  ${({ theme }) => theme.mq.lg} {
    font-size: 1.9rem;
    line-height: 3.6rem;
    margin-left: 2.5rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    font-size: 2.4rem;
    line-height: 4.2rem;
  }
`;
