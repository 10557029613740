import React, { useEffect, useRef } from 'react';
import Parallax from 'parallax-js';
import { isBrowser } from 'react-device-detect';

import { StyledSlide, StyledImgWrapper, StyledImg } from './SlideStyles';

const Slide = ({ img }) => {
  const scene = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      const parallaxInstance = new Parallax(scene.current);
      parallaxInstance.enable();

      return () => parallaxInstance.disable();
    }
  }, []);

  return (
    <StyledSlide ref={scene}>
      <StyledImgWrapper data-depth="-0.1">
        <StyledImg image={img} alt="image" />
      </StyledImgWrapper>
    </StyledSlide>
  );
};

export default Slide;
