import React, { useRef, useEffect } from 'react';
import Parallax from 'parallax-js';
import { isBrowser } from 'react-device-detect';

import { StyledWrapper } from './BackgroundShapes3Styles';

const BackgroundShapes3 = () => {
  const scene = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      const parallaxInstance = new Parallax(scene.current);
      parallaxInstance.enable();

      return () => parallaxInstance.disable();
    }
  }, []);

  return (
    <StyledWrapper ref={scene}>
      <svg
        data-depth={(Math.random() - 0.9) / 2}
        width="141"
        height="141"
        viewBox="0 0 141 141"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="70.5" cy="70.5" r="70.5" fill="#513497" />
      </svg>
    </StyledWrapper>
  );
};

export default BackgroundShapes3;
