import React, { useEffect, useRef } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { simpleFadeIn } from 'theme/Animations';
import Eclipse from './svg/Eclipse';
import CompanyName from './svg/CompanyName';
import {
  StyledSuccessWrapper,
  StyledSuccesImgwrapper,
  StyledSuccesText,
  StyledBubbleSmall,
  StyledBubbleMedium,
  StyledBubbleBig,
} from './SuccessStyle';

const SuccessLogo = () => {
  const intl = useIntl();
  let item = useRef(null);

  useEffect(() => {
    simpleFadeIn(item);
  }, []);

  return (
    <StyledSuccessWrapper
      ref={el => {
        item = el;
      }}
    >
      <StyledSuccesImgwrapper>
        <StyledBubbleSmall />
        <StyledBubbleMedium />
        <StyledBubbleBig />

        <Eclipse />

        <CompanyName />
      </StyledSuccesImgwrapper>

      <StyledSuccesText>
        {intl.formatMessage({ id: 'form_submit_success_first_line' })}
        <br />
        {intl.formatMessage({ id: 'form_submit_success_second_line' })}
      </StyledSuccesText>
    </StyledSuccessWrapper>
  );
};

export default SuccessLogo;
