import React, { useEffect, useRef } from 'react';
import Parallax from 'parallax-js';
import { isBrowser } from 'react-device-detect';

import { StyledBgWrapper, StyledBg } from './BackgroundStyles';

const AloeBackground = ({ data }) => {
  const scene = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      const parallaxInstance = new Parallax(scene.current);
      parallaxInstance.enable();

      return () => parallaxInstance.disable();
    }
  }, []);

  return (
    <StyledBgWrapper ref={scene}>
      <StyledBg
        fluid={data.localFile.childImageSharp.fluid}
        data-depth="-0.04"
      />
    </StyledBgWrapper>
  );
};

export default AloeBackground;
