import React, { useRef, useEffect } from 'react';
import { isBrowser } from 'react-device-detect';
import { benefitsAnimation } from 'theme/Animations';

import Icon from 'components/Benefits/Icon';
import parse from 'html-react-parser';

import Brush from 'components/atoms/icons/benefits/Brush';
import Drop from 'components/atoms/icons/benefits/Drop';
import Face from 'components/atoms/icons/benefits/Face';
import Hair from 'components/atoms/icons/benefits/Hair';
import Heart from 'components/atoms/icons/benefits/Heart';
import Peeling from 'components/atoms/icons/benefits/Peeling';
import Sun from 'components/atoms/icons/benefits/Sun';

import {
  StyledSection,
  StyledBgwrapper,
  StyledImg,
  StyledContentWrapper,
  StyledHeading,
  StyledItemsWrapper,
  StyledItem,
  StyledLabel,
} from './BenefitsStyles';

const Benefits = ({ data }) => {
  let container = useRef(null);
  let title = useRef(null);
  let items = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      benefitsAnimation(container, title, items);
    }
  }, []);

  const images = [
    <Hair />,
    <Peeling />,
    <Brush />,
    <Face />,
    <Drop />,
    <Heart />,
    <Sun />,
  ];

  return (
    <StyledSection
      ref={el => {
        container = el;
      }}
    >
      <StyledBgwrapper>
        <StyledImg fluid={data.background.localFile.childImageSharp.fluid} />
      </StyledBgwrapper>

      <StyledContentWrapper>
        <StyledHeading
          ref={el => {
            title = el;
          }}
        >
          {parse(data.title)}
        </StyledHeading>
        <StyledItemsWrapper
          ref={el => {
            items = el;
          }}
        >
          {data.list.items.slice(0, 7).map((item, index) => (
            <StyledItem key={index}>
              <Icon>{images[index]}</Icon>
              <StyledLabel>{parse(item)}</StyledLabel>
            </StyledItem>
          ))}
        </StyledItemsWrapper>
      </StyledContentWrapper>
    </StyledSection>
  );
};

export default Benefits;
