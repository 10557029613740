import styled from "styled-components";

export const Desktop = styled('div')`
    display: none;

    svg {
        width: 404px;
        height: 193px;
    }

    ${({ theme }) => theme.mq.lg} {
        display: block;
    }

    ${({ theme }) => theme.mq.xl} {
        svg {
            width: 539px;
            height: 258px;
        }
    }
`

export const Mobile = styled('div')`
    display: block;

    svg {
        width: 237px;
        height: 113px;
    }

    ${({ theme }) => theme.mq.lg} {
        display: none;
    }
`
