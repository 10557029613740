import styled from "styled-components";

export const StyledSuccessWrapper = styled('div')`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
`;

export const StyledSuccesImgwrapper = styled('div')`
    position: relative;
`;

export const StyledBubbleSmall = styled('div')`
    width: 11px;
    height: 11px;
    background-color: ${({ theme })=>theme.colors.purpleLight};
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
`;

export const StyledBubbleMedium = styled('div')`
    width: 17px;
    height: 17px;
    background-color: ${({theme})=>theme.colors.purpleLight};
    border-radius: 50%;
    position: absolute;
    right: -40px;
    top: 50%;
    transform: translateY(-50%);

`;

export const StyledBubbleBig = styled('div')`
    width: 27px;
    height: 27px;
    background-color: ${({theme})=>theme.colors.purpleLight};
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
`;

export const StyledSuccesText = styled('span')`
    display: block;
    margin-top: 20px;
    text-align: center;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 3.2rem;
    padding: 0 30px;
    max-width: 560px;    
    color: ${({theme})=>theme.colors.purpleLightest};
    ${({ theme }) => theme.mq.md} {
        margin-top: 40px;
        font-size: 2.4rem;
        line-height: 4.2rem;
    }
`;
