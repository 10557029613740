import styled from "styled-components";
import BackgroundImage from 'gatsby-background-image';

export const StyledHeroBackground = styled(BackgroundImage)`
    background-color:${({ theme }) => theme.colors.purple};
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`

export const StyledTitle = styled('div')`
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
    padding-bottom: 3rem;
    ${({ theme }) => theme.mq.xl} {
        padding-bottom: 6rem;
    }
`

export const StyledSubtitle = styled('span')`
    font-size: 2.6rem;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    color: ${({ theme }) => theme.colors.white};
    ${({ theme }) => theme.mq.lg} {
        font-size: 3rem;
    }
`

export const StyledContact = styled('div')`
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.8rem 5rem;
    ${({ theme }) => theme.mq.lg} {
        display: block;
    }
`

export const StyledSocial = styled('div')`
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 3.6rem 5rem;
    ${({ theme }) => theme.mq.lg} {
        display: block;
    }
`
