import React, { useState, useRef, useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import { IconCheck } from 'components/atoms/icons';
import {
  StyledInputWrapper,
  StyledLabel,
  StyledCheckbox,
  StyledCheckmark,
  StyledLabelContent,
  StyledCollapseButton,
  StyledExpand,
  StyledError,
} from './StyledCheckbox';

const Checkbox = ({
  children,
  onChange,
  onBlur,
  name,
  value,
  errorMessage,
}) => {
  const maxHeight = 52;

  const intl = useIntl();

  const ref = useRef();
  const [shouldShowExpand, setShouldShowExpand] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [expandTo, setExpandTo] = useState(0);

  useEffect(() => {
    if (ref.current.scrollHeight > maxHeight) {
      setShouldShowExpand(true);
      setExpandTo(ref.current.scrollHeight);
      setExpanded(false);
    }
  }, [maxHeight]);

  return (
    <StyledInputWrapper>
      <StyledLabel htmlFor={name}>
        <StyledCheckbox
          name={name}
          value={value}
          type="checkbox"
          id={name}
          onChange={e => onChange(e)}
          onBlur={e => onBlur(e)}
        />
        <StyledCheckmark checked={value}>
          <IconCheck />
        </StyledCheckmark>

        <StyledLabelContent>
          {errorMessage && <StyledError> {errorMessage}</StyledError>}
          <StyledExpand ref={ref} maxHeight={expanded ? expandTo : maxHeight}>
            {children}
          </StyledExpand>

          {shouldShowExpand && (
            <StyledCollapseButton
              type="button"
              onClick={() => setExpanded(!expanded)}
            >
              {intl.formatMessage({ id: 'form_label_collapse' })}
            </StyledCollapseButton>
          )}
        </StyledLabelContent>
      </StyledLabel>
    </StyledInputWrapper>
  );
};

Checkbox.propTypes = {
  errorMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
};

Checkbox.defaultProps = {
  errorMessage: '',
};

export default Checkbox;
