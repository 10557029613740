import styled from 'styled-components';

export const StyledStep = styled('div')`
  cursor: pointer;
  position: relative;
  width: 100%;
  padding-bottom: 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.mq.xxl} {
    margin-bottom: 3rem;
  }
`;

export const StyledImg = styled('img')`
  top: 0;
  left: 0;
  &:first-child {
    z-index: 1;
    position: relative;
    left: 21% !important;
    width: 72%;
    max-width: 400px;
    ${({ theme }) => theme.mq.lg} {
      top: 10px !important;
      left: 19% !important;
      width: 75%;
    }
    ${({ theme }) => theme.mq.xxxl} {
      left: 22% !important;
      width: 76%;
    }
  }
  &:nth-child(2) {
    z-index: 0;
    position: absolute;
    top: 0% !important;
    left: -16% !important;
    width: 100%;
    ${({ theme }) => theme.mq.lg} {
      left: -20% !important;
      width: 103%;
      top: 2% !important;
    }
    ${({ theme }) => theme.mq.xxxl} {
      left: -14% !important;
      width: 95%;
    }
  }
`;
