import React from 'react';
import withLocale from 'hoc/withLocale';
import { StyledFilm } from './FilmStyles';

const Film = ({ data }) => (
  <StyledFilm
    autoPlay
    muted
    loop
    src={process.env.GATSBY_API_URL + data.film.url}
  />
);

export default withLocale(Film);
