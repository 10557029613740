import React from 'react';

const Drop = () => (
  <svg
    width="29"
    height="36"
    viewBox="0 0 29 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2616 4.27534L25.3603 14.498C28.4162 18.3606 28.9028 23.5185 26.6179 27.8296C24.333 32.1406 19.667 34.8681 14.5578 34.8794C9.44854 34.8681 4.78254 32.1406 2.49762 27.8296C0.212709 23.5185 0.699277 18.3606 3.75525 14.498L11.854 4.27534C12.4922 3.47249 13.494 1.5 14.5578 1.5C15.6216 1.5 16.6233 3.47249 17.2616 4.27534Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Drop;
