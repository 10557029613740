import React, { useRef, useEffect } from 'react';
import Parallax from 'parallax-js';
import { isBrowser } from 'react-device-detect';

import { StyledBg } from './SliderBackgroundStyles';

import circle from './shapes/circle.svg';
import shape1 from './shapes/shape1.svg';
import shape2 from './shapes/shape2.svg';
import shape3 from './shapes/shape3.svg';

const layers = [
  {
    id: 1,
    name: 'circle',
    image: circle,
    dataDepth: '0.3',
  },
  {
    id: 2,
    name: 'shape1',
    image: shape1,
    dataDepth: '-0.1',
  },
  {
    id: 3,
    name: 'shape2',
    image: shape2,
    dataDepth: '0.4',
  },
  {
    id: 4,
    name: 'shape3',
    image: shape3,
    dataDepth: '-0.4',
  },
];

const SliderBackground = () => {
  const scene = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      const parallaxInstance = new Parallax(scene.current);
      parallaxInstance.enable();

      return () => parallaxInstance.disable();
    }
  }, []);

  return (
    <StyledBg ref={scene}>
      {layers.map(layer => (
        <img
          key={layer.id}
          data-depth={layer.dataDepth}
          src={layer.image}
          alt={layer.name}
        />
      ))}
    </StyledBg>
  );
};

export default SliderBackground;
