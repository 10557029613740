import React from 'react';

const Hair = () => (
  <svg
    width="51"
    height="51"
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.15 31.4512V32.3012C33.15 36.5261 29.725 39.9512 25.5 39.9512C21.2751 39.9512 17.85 36.5261 17.85 32.3012V31.4512H0V33.1512H16.1882C16.6335 37.9629 20.6676 41.6454 25.5 41.6512C30.3324 41.6454 34.3665 37.963 34.8118 33.1512H51V31.4512H33.15Z"
      fill="white"
    />
    <path
      d="M31.4355 6.10774C31.5058 5.7364 31.3224 5.36335 30.9856 5.19208C30.567 4.97937 30.0554 5.14607 29.8426 5.56459C29.495 6.24034 21.3741 22.1863 19.8636 29.5941C19.6563 30.4476 19.5511 31.3227 19.55 32.201V32.3013C19.5542 35.5856 22.2157 38.2471 25.5 38.2513C28.7843 38.2471 31.4457 35.5856 31.45 32.3013C31.2594 30.2613 30.9312 28.2365 30.4674 26.2408C29.0385 19.1008 31.4109 6.23694 31.4355 6.10774ZM25.5 36.5513C23.1528 36.5513 21.25 34.6485 21.25 32.3013V32.1917C21.2524 31.431 21.346 30.6734 21.5288 29.9349C22.4842 25.2429 26.3551 16.6724 28.9442 11.2528C28.39 15.7629 27.908 22.1166 28.8005 26.5749C29.2474 28.4598 29.5647 30.3731 29.75 32.3013C29.75 34.6485 27.8472 36.5513 25.5 36.5513Z"
      fill="white"
    />
    <path
      d="M12.75 34.8511C11.3417 34.8511 10.2 35.9927 10.2 37.4011C10.2 38.8094 11.3417 39.9511 12.75 39.9511C14.1584 39.9511 15.3 38.8094 15.3 37.4011C15.3 35.9927 14.1584 34.8511 12.75 34.8511ZM12.75 38.2511C12.2806 38.2511 11.9 37.8705 11.9 37.4011C11.9 36.9317 12.2806 36.5511 12.75 36.5511C13.2194 36.5511 13.6 36.9317 13.6 37.4011C13.6 37.8705 13.2194 38.2511 12.75 38.2511Z"
      fill="white"
    />
    <path
      d="M5.10001 36.5513C3.22226 36.5513 1.70001 38.0735 1.70001 39.9513C1.70001 41.829 3.22226 43.3513 5.10001 43.3513C6.97777 43.3513 8.50001 41.829 8.50001 39.9513C8.50001 38.0735 6.97777 36.5513 5.10001 36.5513ZM5.10001 41.6513C4.16108 41.6513 3.40001 40.8902 3.40001 39.9513C3.40001 39.0123 4.16108 38.2513 5.10001 38.2513C6.03894 38.2513 6.80001 39.0123 6.80001 39.9513C6.80001 40.8902 6.03894 41.6513 5.10001 41.6513Z"
      fill="white"
    />
    <path
      d="M45.9 36.5513C44.0222 36.5513 42.5 38.0735 42.5 39.9513C42.5 41.829 44.0222 43.3513 45.9 43.3513C47.7778 43.3513 49.3 41.829 49.3 39.9513C49.3 38.0735 47.7778 36.5513 45.9 36.5513ZM45.9 41.6513C44.9611 41.6513 44.2 40.8902 44.2 39.9513C44.2 39.0123 44.9611 38.2513 45.9 38.2513C46.8389 38.2513 47.6 39.0123 47.6 39.9513C47.6 40.8902 46.8389 41.6513 45.9 41.6513Z"
      fill="white"
    />
    <path
      d="M38.25 34.8511C36.8417 34.8511 35.7 35.9927 35.7 37.4011C35.7 38.8094 36.8417 39.9511 38.25 39.9511C39.6584 39.9511 40.8 38.8094 40.8 37.4011C40.8 35.9927 39.6584 34.8511 38.25 34.8511ZM38.25 38.2511C37.7806 38.2511 37.4 37.8705 37.4 37.4011C37.4 36.9317 37.7806 36.5511 38.25 36.5511C38.7194 36.5511 39.1 36.9317 39.1 37.4011C39.1 37.8705 38.7194 38.2511 38.25 38.2511Z"
      fill="white"
    />
    <path
      d="M17.85 40.8013C16.4416 40.8013 15.3 41.9429 15.3 43.3513C15.3 44.7596 16.4416 45.9013 17.85 45.9013C19.2583 45.9013 20.4 44.7596 20.4 43.3513C20.4 41.9429 19.2583 40.8013 17.85 40.8013ZM17.85 44.2013C17.3806 44.2013 17 43.8207 17 43.3513C17 42.8819 17.3806 42.5013 17.85 42.5013C18.3194 42.5013 18.7 42.8819 18.7 43.3513C18.7 43.8207 18.3194 44.2013 17.85 44.2013Z"
      fill="white"
    />
    <path
      d="M33.15 40.8013C31.7417 40.8013 30.6 41.9429 30.6 43.3513C30.6 44.7596 31.7417 45.9013 33.15 45.9013C34.5583 45.9013 35.7 44.7596 35.7 43.3513C35.7 41.9429 34.5583 40.8013 33.15 40.8013ZM33.15 44.2013C32.6806 44.2013 32.3 43.8207 32.3 43.3513C32.3 42.8819 32.6806 42.5013 33.15 42.5013C33.6194 42.5013 34 42.8819 34 43.3513C34 43.8207 33.6194 44.2013 33.15 44.2013Z"
      fill="white"
    />
  </svg>
);

export default Hair;
