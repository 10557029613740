import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

export const StyledMoisturize = styled('div')`
  width: 100vw;
  min-height: 70vh;
  background: linear-gradient(
    90deg,
    rgba(233, 227, 206, 1) 30%,
    rgba(246, 242, 229, 1) 70%
  );
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.mq.xl} {
    min-height: 100vh;
    flex-direction: row-reverse;
    align-items: stretch;
    background: linear-gradient(
      90deg,
      rgba(231, 224, 201, 1) 25%,
      rgba(255, 255, 255, 1) 75%
    );
  }
`;

export const StyledImageWrapper = styled('div')`
  align-self: flex-end;
  height: 100%;
  width: 100%;
  ${({ theme }) => theme.mq.xl} {
    width: 50%;
  }
`;

export const StyledImage = styled(GatsbyImage)`
  cursor: pointer;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  ${({ theme }) => theme.mq.md} {
    width: 70%;
  }
  ${({ theme }) => theme.mq.xl} {
    width: 90%;
  }
`;

export const StyledContent = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding: 9.5rem 0 6rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.purple};
  ${({ theme }) => theme.mq.xl} {
    width: 50%;
    justify-content: center;
  }
`;

export const StyledTitle = styled('h2')`
  font-weight: 500;
  align-self: center;
  font-size: 4.6rem;
  ${({ theme }) => theme.mq.md} {
    font-size: 8rem;
    line-height: 8rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    font-size: 10rem;
    line-height: 12rem;
  }
`;

export const StyledSubtitle = styled('span')`
  align-self: center;
  font-size: 1.2rem;
  line-height: 2.5rem;
  ${({ theme }) => theme.mq.md} {
    font-size: 2rem;
    line-height: 4rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 2.5rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    font-size: 2.95rem;
  }
`;
