import styled from 'styled-components';

export const StyledInputWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  padding-left: 25px;
  margin-bottom: 40px;
`;

export const StyledLabel = styled('label')`
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const StyledCheckbox = styled('input')`
  border: 1px solid #C5B7FF;
  border-radius: 5px;
  appearance: none;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const StyledCheckmark = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  transition: background-color .3s;
  border: 1px solid ${({ theme }) => theme.colors.purpleLightest};
  background-color: ${({ checked, theme }) => checked &&  theme.colors.purpleLightest};
  margin-top: 5px;
`;

export const StyledLabelContent = styled('div')`
`;

export const StyledCollapseButton = styled('button')`
  color: ${({ theme }) => theme.colors.purple};
  font-size: inherit;
  line-height: inherit;
  text-decoration: underline;
`;

export const StyledExpand = styled('p')`
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 0;
  overflow: hidden;
  transition: max-height 0.2s ease;
  max-height: ${({maxHeight})=> `${maxHeight}px`};
`;

export const StyledError = styled('span')`
  display: inline-block;
  font-size: 1.2rem;
  color: ${({theme})=>theme.colors.red};
  font-weight: 700;
`;
