import React from 'react';

const Water = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M29.707 9.09778C27.6859 6.14541 25.7769 3.35672 24.8911 0.646312C24.7651 0.260719 24.4056 0 24 0C23.5944 0 23.2348 0.260813 23.1089 0.646312C22.2217 3.36141 20.3119 6.15037 18.2899 9.10303C15.1054 13.7533 11.8125 18.562 11.8125 24C11.8125 26.3237 12.4341 28.516 13.6163 30.4144C9.06431 32.1474 6.1875 35.1762 6.1875 38.3438C6.1875 43.6682 14.1781 48 24 48C26.2267 48 28.4043 47.7767 30.4724 47.3362C30.9788 47.2283 31.3019 46.7303 31.194 46.224C31.0861 45.7177 30.5888 45.3944 30.0818 45.5024C28.1419 45.9156 26.0957 46.125 24 46.125C18.1909 46.125 8.0625 43.6203 8.0625 38.3438C8.0625 35.902 10.7378 33.3929 14.7808 31.9854C17.0976 34.6276 20.4955 36.1875 24 36.1875C27.5725 36.1875 30.8928 34.6628 33.2107 31.9825C37.2589 33.3897 39.9375 35.8999 39.9375 38.3438C39.9375 40.1035 38.5743 41.521 37.4308 42.4002C37.0203 42.7158 36.9434 43.3044 37.259 43.7148C37.575 44.1259 38.1635 44.2019 38.5735 43.8866C40.6926 42.2575 41.8125 40.3408 41.8125 38.3438C41.8125 35.1722 38.9294 32.1407 34.3678 30.4084C35.5605 28.4897 36.1875 26.2989 36.1875 24C36.1875 18.5645 32.893 13.7519 29.707 9.09778ZM24 34.3125C18.5326 34.3125 13.6875 29.9438 13.6875 24C13.6875 19.1425 16.8137 14.5773 19.8369 10.1623C21.4184 7.85297 22.9337 5.64019 24.0002 3.41953C25.0657 5.637 26.5798 7.84875 28.1598 10.1569C31.1847 14.5757 34.3125 19.1448 34.3125 24C34.3125 29.6124 29.7907 34.3125 24 34.3125Z"
        fill="white"
      />
      <path
        d="M35.6343 37.7362C35.2988 37.3418 34.7073 37.294 34.3127 37.6293C33.2597 38.525 29.749 40.2186 24 40.2186C18.2508 40.2186 14.7403 38.525 13.6874 37.6295C13.293 37.2939 12.7012 37.3417 12.3659 37.7363C12.0304 38.1307 12.0783 38.7224 12.4727 39.0578C13.7984 40.1853 17.7182 42.0936 24 42.0936C30.2816 42.0936 34.2015 40.1854 35.5274 39.0578C35.9218 38.7224 35.9696 38.1307 35.6343 37.7362Z"
        fill="white"
      />
      <path
        d="M34.3125 46.125C34.8303 46.125 35.25 45.7053 35.25 45.1875C35.25 44.6697 34.8303 44.25 34.3125 44.25C33.7947 44.25 33.375 44.6697 33.375 45.1875C33.375 45.7053 33.7947 46.125 34.3125 46.125Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Water;
