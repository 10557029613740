export const TemplateEnum = {
  AboutUs: 'TemplateTitleTextLinkImgONas',
  TitleSlider: 'TemplateTitleSliderDesignerskieOpakowania',
  TextSingleImageLeftAndRight: 'TemplateTextSingleImageLeftWyjatkoweFormuly',
  TextImagesWithBackgrounds: 'TemplateTitleTextImagesBackgroundsOczyszczamy',
  TitleTextSliderRight: 'TemplateTitleTextSliderRightOczyszczanieTwarzy',
  TitleTextBigImageLeft: 'TemplateTitleTextBigImageLeftNawilzamy',
  TitleTextListSlider: 'TemplateTitleTextListSliderPielegnacja',
  TitleTextImagesGrid: 'TemplateTitleTextImagesGridPropozycji',
  TitleSliderTitle2Content: 'TemplateTitleSliderTitle2ContentMaskiWPlachcie',
  TitleTextLeftTextRight: 'TemplateTitleTextText2MamyToCos',
  Film: 'TemplateFilm',
  ListWithBackground: 'TemplateListBackgroundAloesKoiNawilza',
  TitleListWithBackground: 'TemplateTitleListBackgroundWieleZalet',
  TypingText: 'TemplateTypingTextStawiamyKropkeNadI',
  TitleTextSlider: 'TemplateTitleTextSliderMakijaz',
  TitleDescriptionImagesCenterList:
    'TemplateTitleDescriptionImagesCenterListKremyBb',
  TitleTextMap: 'TemplateTitleTextMap',
  ContactForm: 'TemplateContactForm',
};
