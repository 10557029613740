import React from 'react';

import { Desktop, Mobile } from './StyledSiteTitle';

const SiteTitle = () => (
  <>
    <Desktop>
      <svg viewBox="0 0 539 258" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M75.6535 117.431V65.1841H12.1542V117.431H0V0.995117H12.1542V52.9932H75.6535V0.995117H87.8076V117.431H75.6535Z"
          fill="white"
        />
        <path
          d="M161.477 118.675C145.106 118.675 131.215 112.704 119.805 101.259C108.395 89.8149 102.442 75.6336 102.442 59.4619C102.442 43.0415 108.395 29.109 119.805 17.4156C131.215 5.97107 145.354 0 161.477 0C177.6 0 191.738 5.72228 203.396 17.4156C214.806 28.8602 220.759 43.0415 220.759 59.4619C220.759 75.6336 215.054 89.8149 203.396 101.259C191.738 112.704 177.6 118.675 161.477 118.675ZM161.477 12.1909C148.578 12.1909 137.416 16.918 128.239 26.1234C119.061 35.3288 114.348 46.5246 114.348 59.4619C114.348 72.3992 119.061 83.595 128.239 92.8004C137.416 102.006 148.578 106.733 161.477 106.733C174.375 106.733 185.537 102.006 194.715 92.8004C203.644 83.3462 208.357 72.1504 208.357 59.2131C208.357 46.2758 203.644 35.08 194.467 25.8746C185.537 16.918 174.375 12.1909 161.477 12.1909Z"
          fill="white"
        />
        <path
          d="M236.882 117.431V0.995117H249.036V105.24H302.118V117.431H236.882Z"
          fill="white"
        />
        <path
          d="M332.131 1.24414H319.977V117.68H332.131V1.24414Z"
          fill="white"
        />
        <path
          d="M415.226 117.431L375.291 68.6673L366.361 79.863V117.431H354.207V1.24391H366.361V60.2082L413.738 0.995117H429.364L382.98 58.9643L431.101 117.431H415.226Z"
          fill="white"
        />
        <path
          d="M526.598 117.431L512.707 83.8437H460.37L446.48 117.431H434.077L482.446 0.995117H490.383L538.752 117.431H526.598ZM507.498 71.6528L486.415 20.6499L465.331 71.6528H507.498Z"
          fill="white"
        />
        <path
          d="M75.6535 256.756V204.509H12.1542V256.756H0V140.32H12.1542V192.318H75.6535V140.32H87.8076V256.756H75.6535Z"
          fill="white"
        />
        <path
          d="M161.477 258C145.106 258 131.215 252.029 119.805 240.585C108.395 229.14 102.442 214.959 102.442 198.787C102.442 182.367 108.395 168.434 119.805 156.741C131.215 145.296 145.354 139.325 161.477 139.325C177.848 139.325 191.738 145.047 203.396 156.741C214.806 168.185 220.759 182.367 220.759 198.787C220.759 214.959 215.054 229.14 203.396 240.585C191.738 252.029 177.6 258 161.477 258ZM161.477 151.516C148.578 151.516 137.416 156.243 128.239 165.449C119.061 174.654 114.348 185.85 114.348 198.787C114.348 211.724 119.061 222.92 128.239 232.126C137.416 241.331 148.578 246.058 161.477 246.058C174.375 246.058 185.537 241.331 194.715 232.126C203.644 222.671 208.357 211.476 208.357 198.538C208.357 185.601 203.644 174.405 194.467 165.2C185.537 156.243 174.375 151.516 161.477 151.516Z"
          fill="white"
        />
        <path
          d="M236.882 256.756V140.32H249.036V244.565H302.118V256.756H236.882Z"
          fill="white"
        />
        <path
          d="M332.131 140.569H319.977V257.005H332.131V140.569Z"
          fill="white"
        />
        <path
          d="M415.226 256.756L375.291 207.992L366.361 219.188V256.756H354.207V140.569H366.361V199.533L413.738 140.32H429.364L382.98 198.289L431.101 256.756H415.226Z"
          fill="white"
        />
        <path
          d="M526.598 256.756L512.707 223.169H460.37L446.48 256.756H434.077L482.446 140.32H490.383L538.752 256.756H526.598ZM507.498 210.978L486.415 159.975L465.331 210.978H507.498Z"
          fill="white"
        />
      </svg>
    </Desktop>
    <Mobile>
      <svg viewBox="0 0 237 113" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M104.158 51.2964V0.43457H109.502V45.9712H132.842V51.2964H104.158Z"
          fill="white"
        />
        <path
          d="M33.2651 51.2964V28.4738H5.34422V51.2964H0V0.43457H5.34422V23.1485H33.2651V0.43457H38.6093V51.2964H33.2651Z"
          fill="white"
        />
        <path
          d="M146.039 0.542969H140.695V51.4049H146.039V0.542969Z"
          fill="white"
        />
        <path
          d="M71.0019 51.84C63.8035 51.84 57.6958 49.2317 52.6788 44.2324C47.6618 39.2332 45.0442 33.0385 45.0442 25.9743C45.0442 18.8015 47.6618 12.7155 52.6788 7.60755C57.6958 2.6083 63.9126 0 71.0019 0C78.0911 0 84.3079 2.49962 89.434 7.60755C94.451 12.6068 97.0686 18.8015 97.0686 25.9743C97.0686 33.0385 94.5601 39.2332 89.434 44.2324C84.3079 49.2317 78.0911 51.84 71.0019 51.84ZM71.0019 5.32528C65.3304 5.32528 60.4225 7.39019 56.387 11.4113C52.3516 15.4324 50.2793 20.323 50.2793 25.9743C50.2793 31.6257 52.3516 36.5162 56.387 40.5373C60.4225 44.5585 65.3304 46.6234 71.0019 46.6234C76.6733 46.6234 81.5812 44.5585 85.6167 40.5373C89.543 36.4075 91.6153 31.517 91.6153 25.8657C91.6153 20.2143 89.543 15.3238 85.5076 11.3026C81.5812 7.39019 76.6733 5.32528 71.0019 5.32528Z"
          fill="white"
        />
        <path
          d="M182.576 51.2964L165.017 29.9953L161.09 34.8859V51.2964H155.746V0.54325H161.09V26.3002L181.922 0.43457H188.793L168.398 25.7568L189.556 51.2964H182.576Z"
          fill="white"
        />
        <path
          d="M231.547 51.2964L225.439 36.6248H202.426L196.319 51.2964H190.865L212.133 0.43457H215.623L236.891 51.2964H231.547ZM223.149 31.2995L213.878 9.02023L204.608 31.2995H223.149Z"
          fill="white"
        />
        <path
          d="M33.2651 112.157V89.3342H5.34422V112.157H0V61.2949H5.34422V84.0089H33.2651V61.2949H38.6093V112.157H33.2651Z"
          fill="white"
        />
        <path
          d="M71.0019 112.7C63.8035 112.7 57.6958 110.092 52.6788 105.093C47.6618 100.094 45.0442 93.8988 45.0442 86.8347C45.0442 79.6619 47.6618 73.5758 52.6788 68.4679C57.6958 63.4687 63.9126 60.8604 71.0019 60.8604C78.2002 60.8604 84.3079 63.36 89.434 68.4679C94.451 73.4671 97.0686 79.6619 97.0686 86.8347C97.0686 93.8988 94.5601 100.094 89.434 105.093C84.3079 110.092 78.0911 112.7 71.0019 112.7ZM71.0019 66.1856C65.3304 66.1856 60.4225 68.2505 56.387 72.2717C52.3516 76.2928 50.2793 81.1834 50.2793 86.8347C50.2793 92.486 52.3516 97.3766 56.387 101.398C60.4225 105.419 65.3304 107.484 71.0019 107.484C76.6733 107.484 81.5812 105.419 85.6167 101.398C89.543 97.2679 91.6153 92.3773 91.6153 86.726C91.6153 81.0747 89.543 76.1841 85.5076 72.163C81.5812 68.2505 76.6733 66.1856 71.0019 66.1856Z"
          fill="white"
        />
        <path
          d="M104.158 112.157V61.2949H109.502V106.832H132.842V112.157H104.158Z"
          fill="white"
        />
        <path
          d="M146.039 61.4033H140.695V112.265H146.039V61.4033Z"
          fill="white"
        />
        <path
          d="M182.576 112.157L165.017 90.8557L161.09 95.7462V112.157H155.746V61.4036H161.09V87.1606L181.922 61.2949H188.793L168.398 86.6172L189.556 112.157H182.576Z"
          fill="white"
        />
        <path
          d="M231.547 112.157L225.439 97.4851H202.426L196.319 112.157H190.865L212.133 61.2949H215.623L236.891 112.157H231.547ZM223.149 92.1598L213.878 69.8806L204.608 92.1598H223.149Z"
          fill="white"
        />
      </svg>
    </Mobile>
  </>
);

export default SiteTitle;
