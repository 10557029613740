import styled from "styled-components";

export const StyledFunction = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const StyledFunctionTitle = styled('span')`
    text-align: center;
    font-size: 2.4rem;
    line-height: 2.9rem;
    color: hsla(0, 83%, 82%, 1);
    font-family: 'Montserrat', serif;
    font-weight: 600;
    ${({ theme }) => theme.mq.xxl} {
        font-size: 3.6rem;
        line-height: 4.4rem;     
    }
`;

export const StyledIcon = styled('div')`
    height: 120px;
    width: 120px;
    border-radius: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    svg{
        width: 69%;
    }
    ${({ theme }) => theme.mq.lg} {
        background-color: hsla(258, 66%, 58%, 1);
        align-items: center;
        height: 93px;
        width: 93px;
        margin-top: 2rem;
    }
    ${({ theme }) => theme.mq.xxl} {
        height: 120px;
        width: 120px;
    }
`;
