import styled, {
    keyframes
} from "styled-components";

const swipeAnimation = keyframes `
    0% {
        transform: translate(-50%, 40px) scale(0.5, 0.5);
        opacity: 1;
        animation-timing-function: ease-in;
    }
    10% {
        transform: translate(-50%, 20px) scale(0.4, 0.65);
        animation-timing-function: ease-out;
    }
    20% {
        transform: translate(-50%, 0px) scale(0.6, 0.4);
        animation-timing-function: ease;
    }
    30% {
        transform: translate(-50%, 0px) scale(0.5, 0.5);
        animation-timing-function: ease-in;
        opacity: 1;
        filter: blur(0px);
    }
    50% {
        transform: translate(-50%, 0px) scale(0.9, 0.9);
        opacity: 0.4;
        filter: blur(0.5px);
    }
    65% {
        transform: translate(-50%, 20px) scale(1, 1.2);
        filter: blur(1px);
        opacity: 0.3;
    }
    80% {
        transform: translate(-50%, 40px) scale(1.05, 0.95);
        animation-timing-function: ease-out;
        opacity: 0.4;
        filter: blur(0.5px);
    }
    100% {
        transform: translate(-50%, 40px) scale(0.5, 0.5);
        opacity: 1;
        filter: blur(0px);
    }
`;

const scrollAnimation = keyframes`
    0% { 
        background-position: 0% 100%; 
    }
    20% { 
        background-position: 0% 0%; 
    }
    21% { 
        background-color: ${({ theme }) => theme.colors.purpleDark}; 
    }
    29.99% { 
        background-color: ${({ theme }) => theme.colors.white};
        background-position: 0% 0%;
    }
    30% { 
        background-color: ${({ theme }) => theme.colors.purpleDark};
        background-position: 0% 100%;
    }
    50% { 
        background-position: 0% 0%; 
    }
    51% { 
        background-color: ${({ theme }) => theme.colors.purpleDark}; 
    }
    59% { 
        background-color: ${({ theme }) => theme.colors.white};
        background-position: 0% 0%;
    }
    60% { 
        background-color: ${({ theme }) => theme.colors.purpleDark};
        background-position: 0% 100%;
    }
    80% { 
        background-position: 0% 0%; 
    }
    81% { 
        background-color: ${({ theme }) => theme.colors.purpleDark}; 
    }
    90%, 100% { 
        background-color: ${({ theme }) => theme.colors.white}; 
    }
`;

const mouseAnimation = keyframes `
    0% { 
        transform: translate(-50%, calc(-50% - 4px )); 
    }
    20% { 
        transform: translate(-50%, calc(-50% + 4px )); 
    }
    30% { 
        transform: translate(-50%, calc(-50% - 4px )); 
    }
    50% { 
        transform: translate(-50%, calc(-50% + 4px )); 
    }
    60% { 
        transform: translate(-50%, calc(-50% - 4px )); 
    }
    80% { 
        transform: translate(-50%, calc(-50% + 4px )); 
    }
    100% { 
        transform: translate(-50%, calc(-50% - 4px )); 
    }
`;

const trackBallAnimation = keyframes `
    0% {
        opacity: 1;
        transform: scale(1) translateY(-18px);
    }
    6% { 
        opacity: 1;
        transform: scale(0.9) translateY(calc( 18px / 4 ));
    }
    14% {
        opacity: 0;
        transform: scale(0.4) translateY(calc( 18px * 2 ));
    }
    15%, 19% {
        opacity: 0;
        transform: scale(0.4) translateY(-18px);
    }
    28%, 29.99% {
        opacity: 1;
        transform: scale(1) translateY(-18px);
    }
    30% {
        opacity: 1;
        transform: scale(1) translateY(-18px);
    }
    36% { 
        opacity: 1;
        transform: scale(0.9) translateY(calc( 18px / 4 ));
    }
    44% {
        opacity: 0;
        transform: scale(0.4) translateY(calc( 18px * 2 ));
    }
    45%, 49% {
        opacity: 0;
        transform: scale(0.4) translateY(-18px);
    }
    58%, 59.99% {
        opacity: 1;
        transform: scale(1) translateY(-18px);
    }
    60% {
        opacity: 1;
        transform: scale(1) translateY(-18px);
    }
    66% { 
        opacity: 1;
        transform: scale(0.9) translateY(calc( 18px / 4 ));
    }
    74% {
        opacity: 0;
        transform: scale(0.4) translateY(calc( 18px * 2 ));
    }
    75%, 79% {
        opacity: 0;
        transform: scale(0.4) translateY(-18px);
    }
    88%, 100% {
        opacity: 1;
        transform: scale(1) translateY(-18px);
    }
`;

export const Desktop = styled('div')`
    display: none;

    ${({ theme }) => theme.mq.lg} {
        display: block;
    }
`

export const Mobile = styled('div')`
    display: block;

    ${({ theme }) => theme.mq.lg} {
        display: none;
    }
`


export const StyledSwipeUpWrapper = styled('div')`
    width: 76px;
    height: 76px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.purpleDark};
    position: absolute;
    left: 50%;
    bottom: 50px;
    transform: translate(-50%,0);
`

export const StyledSwipeUp = styled('div')`
    width: 76px;
    height: 76px;
    position: absolute;
    left: 50%;
    bottom: 50px;
    transform: translate(-50%,0);
    &::before {
        display: block;
        position: absolute;
        left: 50%;
        top: 75%;
        content: '';
        border-radius: 50%;
        width: 24px;
        height: 24px;
        transform: translate(-50%, 40px) scale(0.5, 0.5);
        background: ${({ theme }) => theme.colors.white};
        animation: ${swipeAnimation} 1.7s linear infinite;
    }
`

export const StyledScrollWrapper = styled('div')`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.purpleDark};
    position: absolute;
    left: 50%;
    bottom: 50px;
    transform: translate(-50%,0);
`

export const StyledScroll = styled('div')`
    background: ${({ theme }) => theme.colors.purpleDark} linear-gradient(
      transparent 0%,
      transparent 50%,
      ${({ theme }) => theme.colors.white} 50%,
      ${({ theme }) => theme.colors.white} 100%
    );
    position: absolute;
    top: 50%;
    left: 50px;
    transform: translate(-50%, -50%);
    width: 34px;
    height: 54px;
    border-radius: 100px;
    background-size: 100% 200%;
    animation: ${scrollAnimation} 5s linear infinite, ${mouseAnimation} 5s ease-out infinite;
    &::before,
    &::after {
    content: "";
        position: absolute;
        top: 0; 
        right: 0; 
        bottom: 0; 
        left: 0;
        margin: auto;
    }
    &::before {
        width: 30px;
        height: 50px;
        background-color: ${({ theme }) => theme.colors.purple};
        border-radius: 100px;
    }
    &::after {
        background-color: ${({ theme }) => theme.colors.white};
        width: 8px;
        height: 8px;
        border-radius: 100%;
        animation: ${trackBallAnimation} 5s linear infinite;
    }
`

export const StyledText = styled('div')`
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.2rem;
    text-transform: uppercase;
    position: absolute;
    left: 50%;
    bottom: 50px;
    transform: translate(-50%, 24px);
`
