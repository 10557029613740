import React, { useRef, useEffect } from 'react';
import parse from 'html-react-parser';
import { getImage } from 'gatsby-plugin-image';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { isBrowser } from 'react-device-detect';
import withLocale from 'hoc/withLocale';
import SliderBackground from 'components/Makeup/SliderBackground';
import Slide from 'components/Makeup/Slide';
import BgCircle from 'components/Makeup/BgCircle';

import { makeupAnimation } from 'theme/Animations';

import {
  StyledMakeup,
  StyledContainer,
  StyledContentWrapper,
  StyledTitle,
  StyledTitleMobile,
  StyledText,
  SliderWrapper,
  StyledSlider,
  StyledInnerWrapper,
  StyledImgWrapper,
} from './MakeupStyles';

const settings = {
  infinite: true,
  speed: 1000,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  dots: true,
  autoplay: true,
  autoplaySpeed: 3000,
  appendDots: dots => <ul className="clean-slider-dots">{dots}</ul>,
  customPaging: () => <div className="ft-slick__dots--custom" />,
};

const Makeup = ({ data, currentLocale }) => {
  let container = useRef(null);
  let title = useRef(null);
  let titleAlt = useRef(null);
  let content = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      makeupAnimation(container, title, titleAlt, content);
    }
  }, []);

  return (
    <StyledMakeup
      ref={el => {
        container = el;
      }}
    >
      <StyledContainer>
        <StyledContentWrapper>
          <StyledTitle
            ref={el => {
              title = el;
            }}
          >
            {parse(data.title)}
          </StyledTitle>
          <StyledText
            ref={el => {
              content = el;
            }}
          >
            {parse(data.text)}
          </StyledText>
        </StyledContentWrapper>

        <SliderWrapper>
          <StyledTitleMobile
            ref={el => {
              titleAlt = el;
            }}
          >
            {parse(data.title)}
          </StyledTitleMobile>
          <StyledInnerWrapper>
            <SliderBackground />

            <StyledSlider {...settings}>
              {data.images.map((image, idx) => {
                const gatsbyImage = getImage(image.localFile);
                const alt = image.alternativeText ?? 'makeup';
                return (
                  <StyledImgWrapper
                    onClick={() => {
                      if (image.caption) {
                        const links = image.caption.split('|');
                        window.open(
                          currentLocale === 'en' && links[1]
                            ? links[1]
                            : links[0],
                          '_blank'
                        );
                      }
                    }}
                  >
                    <Slide key={idx} img={gatsbyImage} alt={alt} />;
                  </StyledImgWrapper>
                );
              })}
            </StyledSlider>
          </StyledInnerWrapper>
        </SliderWrapper>
      </StyledContainer>

      <BgCircle />
    </StyledMakeup>
  );
};

export default withLocale(Makeup);
