import styled, { css } from "styled-components";
import Img from "gatsby-image";

export const StyledSectionWrapper = styled('div')`
    background-color:  ${({ theme }) => theme.colors.purpleLightest};
    position: relative;
    z-index: 0;
    &:before{
        content: "";
        height: 10px;
        width: 100%;
        position: absolute;
        bottom: -5px;
        left: 0;
        background-color:  ${({ theme }) => theme.colors.purpleDarker};
    }
`;

export const StyledTitle = styled('h2')`
    font-size: 2.4rem;
    line-height: 3.6rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.purple};
    ${({ theme }) => theme.mq.lg} {
        font-size: 3.6rem;
        line-height: 4.4rem;
    }
`;

export const StyledTitelWrapper = styled('div')`
    margin-bottom: 6.5rem;
`;

export const StyledSubtitle = styled('h2')`
    font-size: 1.6rem;
    text-align: center;
    line-height: 2.8rem;
    margin-top: 2rem;
    color: ${({ theme }) => theme.colors.purple};
`;

export const StyledBackgroundWrapper = styled('div')`
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
`;

export const StyledBackgroundInnerWrapper = styled('div')`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
    ${({ theme }) => theme.mq.lg} {
        height: auto;
    }
`;

export const StyledBackground = styled(Img)`
    height: 100%;
    ${({ theme }) => theme.mq.lg} {
        width: 100%;
        height: auto;
    }
`;

export const StyledGradient = styled('div')`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(180deg, hsla(258, 53%, 34%, 0) 0%, hsla(258, 53%, 34%, 1) 43.43%);
`;

export const StyledFormWrapper = styled('div')`
    padding: 12rem 0;
    ${({ theme }) => theme.mq.lg} {
        padding: 11rem 0;
    }
`;

export const StyledFormInnerWrapper = styled('div')`
    position: relative;
`;

export const StyledBlurWrapper = styled('div')`
    overflow: hidden;
    padding: 3rem;
    max-width: 100%;
    background: ${({ theme }) => theme.colors.white};
    ${({ theme }) => theme.mq.smm} {
        margin: 0 2.5rem;
        border-radius: 4px;
    }
    ${({ theme }) => theme.mq.md} {
        padding: 4rem 3rem;
    }
    ${({ theme }) => theme.mq.lg} {
        max-width: 830px;
        margin: 0 auto;
    }
`

export const StyledForm = styled('form')`
    width: 100%;
    margin: 0;
    overflow: hidden;
    background: ${({ theme }) => theme.colors.white};
    transition: filter .5s;
    &::after{
          transition: .5s;
          content: "";
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          opacity: 0;
          visibility: hidden;
          overflow: hidden;
          background-color: rgba(255,255,255, 0);
    }
    ${({ theme }) => theme.mq.smm} {
        border-radius: 5px;
    }
    ${({ blur }) =>
    blur &&
    css`
      filter: blur(4px);
      position: relative;
      &::after{
          opacity: 1;
          visibility: visible;
          overflow: visible;
          background-color: rgba(255,255,255, .8);
      }
    `}
`;

export const StyledInputRow = styled('div')`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    ${({ theme }) => theme.mq.md} {
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const StyledSubmitBtn = styled('button')`
    width: 100%;
    padding: 15px;
    text-align: center;
    border-radius: 4px;
    font-size: 1.6rem;
    line-height: 1.6rem;
    font-weight: 700; 
    background-color: ${({ theme }) => theme.colors.purpleLightest};
    color: ${({ theme }) => theme.colors.white};
    transition: background-color .3s;
    &:hover{
        background-color: ${({ theme }) => theme.colors.purpleDarkest};
    }
`;

