import React, { useRef, useEffect } from 'react';
import parse from 'html-react-parser';
import Parallax from 'parallax-js';
import { isBrowser } from 'react-device-detect';
import { getImage } from 'gatsby-plugin-image';
import withLocale from 'hoc/withLocale';
import { masksAnimation } from 'theme/Animations';
import 'swiper/swiper-bundle.css';
import Swiper from 'react-id-swiper';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import image1 from 'svg/mask_face_1.svg';
import image2 from 'svg/mask_face_2.svg';
import image3 from 'svg/mask_face_3.svg';
import image4 from 'svg/mask_face_4.svg';
import image5 from 'svg/mask_face_5.svg';
import maskBg from 'svg/mask_bg.svg';

import {
  StyledMasks,
  StyledContainer,
  StyledMainSection,
  StyledSecondSection,
  StyledSection,
  StyledContent,
  StyledTitle,
  StyledSwiper,
  StyledSubTitle,
  StyledParagraph,
  StyledFace,
  StyledImg,
} from './MasksStyles';

SwiperCore.use([Pagination, Autoplay]);

const sliderParams = {
  slidesPerView: 3,
  slidesPerGroup: 3,
  spaceBetween: 16,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  loop: true,
  breakpoints: {
    1800: {
      slidesPerView: 4,
      slidesPerGroup: 4,
      spaceBetween: 30,
    },
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
};

const layers = [
  {
    id: 1,
    name: 'text',
    image: image1,
    dataDepth: '0.1',
  },
  {
    id: 2,
    name: 'astronaut',
    image: image2,
    dataDepth: '1.2',
  },
  {
    id: 3,
    name: 'planet1',
    image: image3,
    dataDepth: '0.8',
  },
  {
    id: 4,
    name: 'planet2',
    image: image4,
    dataDepth: '-0.8',
  },
  {
    id: 5,
    name: 'rocket',
    image: image5,
    dataDepth: '0.5',
  },
];

const Masks = ({ data, currentLocale }) => {
  const scene = useRef(null);
  let title = useRef(null);
  let subtitle = useRef(null);
  let paragraph = useRef(null);
  let content = useRef(null);
  let slider = useRef(null);
  let mainSection = useRef(null);
  let secondarySection = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      masksAnimation(
        mainSection,
        title,
        secondarySection,
        subtitle,
        paragraph,
        slider,
        content
      );
      const parallaxInstance = new Parallax(scene.current);
      parallaxInstance.enable();

      return () => parallaxInstance.disable();
    }
  }, []);

  return (
    <StyledMasks>
      <StyledContainer>
        <StyledMainSection
          ref={el => {
            mainSection = el;
          }}
        >
          <StyledTitle
            ref={el => {
              title = el;
            }}
          >
            <img src={maskBg} alt="Maseczki w płachcie" />
            {parse(data.title)}
          </StyledTitle>
          <StyledSwiper
            ref={el => {
              slider = el;
            }}
          >
            <Swiper {...sliderParams}>
              {data.images.map((image, idx) => {
                const gatsbyImage = getImage(image.localFile);
                const alt = image.alternativeText ?? 'mask';
                return (
                  <div
                    onClick={() => {
                      if (image.caption) {
                        const links = image.caption.split('|');
                        window.open(
                          currentLocale === 'en' && links[1]
                            ? links[1]
                            : links[0],
                          '_blank'
                        );
                      }
                    }}
                  >
                    <StyledImg
                      image={gatsbyImage}
                      alt={alt}
                      key={idx}
                      imgStyle={{
                        opacity: 1,
                      }}
                      placeholderstyle={{
                        opacity: 0,
                      }}
                    />
                  </div>
                );
              })}
            </Swiper>
          </StyledSwiper>
        </StyledMainSection>
        <StyledSecondSection
          ref={el => {
            secondarySection = el;
          }}
        >
          <StyledSection>
            <StyledContent
              ref={el => {
                content = el;
              }}
            >
              <StyledSubTitle
                ref={el => {
                  subtitle = el;
                }}
              >
                {parse(data.title2)}
              </StyledSubTitle>
              <StyledParagraph
                ref={el => {
                  paragraph = el;
                }}
              >
                {parse(data.text)}
              </StyledParagraph>
            </StyledContent>
            <StyledFace ref={scene}>
              {layers.map(layer => (
                <img
                  key={layer.id}
                  data-depth={layer.dataDepth}
                  src={layer.image}
                  alt={layer.name}
                />
              ))}
            </StyledFace>
          </StyledSection>
        </StyledSecondSection>
      </StyledContainer>
    </StyledMasks>
  );
};

export default withLocale(Masks);
