import React, { useRef, useEffect } from 'react';
import Parallax from 'parallax-js';
import { isBrowser } from 'react-device-detect';

import { StyledWrapper } from './BackgroundShapes4Styles';

const BackgroundShapes4 = () => {
  const scene = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      const parallaxInstance = new Parallax(scene.current);
      parallaxInstance.enable();

      return () => parallaxInstance.disable();
    }
  }, []);

  return (
    <StyledWrapper ref={scene}>
      <svg
        data-depth={(Math.random() - 0.9) / 2}
        width="368"
        height="334"
        viewBox="0 0 368 334"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M368 185.5C368 279.665 292.165 334 198 334C103.835 334 0.5 295.165 0.5 201C0.5 106.835 116.335 0.5 210.5 0.5C304.665 0.5 368 91.3354 368 185.5Z"
          fill="#503199"
        />
      </svg>

      <svg
        data-depth={(Math.random() - 0.9) / 2}
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="24" r="24" fill="#503199" />
      </svg>

      <svg
        data-depth={(Math.random() - 0.9) / 2}
        width="73"
        height="73"
        viewBox="0 0 73 73"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="36.5" cy="36.5" r="36.5" fill="#503199" />
      </svg>
    </StyledWrapper>
  );
};

export default BackgroundShapes4;
