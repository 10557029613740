import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import SliderBackground from 'components/CleanSlider/Background';
import parse from 'html-react-parser';

import Step1 from 'components/CleanSlider/steps/Step1';

import {
  StyledCleanSlider,
  StyledContainer,
  StyledContentWrapper,
  StyledTitle,
  StyledText,
  SliderWrapper,
  StyledSlider,
  StyledImgWrapper,
} from './CleanSliderStyles';

const settings = {
  infinite: true,
  speed: 1000,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  dots: true,
  autoplay: true,
  autoplaySpeed: 3000,
  appendDots: dots => <ul className="clean-slider-dots">{dots}</ul>,
  customPaging: () => <div className="ft-slick__dots--custom" />,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        dots: false,
      },
    },
  ],
};

const CleanSlider = ({ data, currentLocale }) => (
  <StyledCleanSlider>
    <SliderBackground />

    <StyledContainer>
      <StyledContentWrapper>
        <div>
          <StyledText>
            <StyledTitle>{parse(data.title)}</StyledTitle>
            {parse(data.text)}
          </StyledText>
        </div>
      </StyledContentWrapper>

      <SliderWrapper>
        <StyledSlider {...settings}>
          {data.images.map((image, index) => (
            <StyledImgWrapper
              onClick={() => {
                if (image.caption) {
                  const links = image.caption.split('|');
                  window.open(
                    currentLocale === 'en' && links[1] ? links[1] : links[0],
                    '_blank'
                  );
                }
              }}
              key={index}
            >
              <Step1 key={index} image={image} />
            </StyledImgWrapper>
          ))}
        </StyledSlider>
      </SliderWrapper>
    </StyledContainer>
  </StyledCleanSlider>
);

export default CleanSlider;
