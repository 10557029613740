import React, { forwardRef } from 'react';
import parse from 'html-react-parser';
import {
  StyledFunction,
  StyledFunctionTitle,
  StyledIcon,
} from './CreamIconStyles';
import IconCream from '../atoms/icons/cream';

const CreamIcon = (props, ref) => (
  <StyledFunction ref={ref}>
    <StyledFunctionTitle>{parse(props.text)}</StyledFunctionTitle>
    <StyledIcon>
      <IconCream />
    </StyledIcon>
  </StyledFunction>
);

export default forwardRef(CreamIcon);
