import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { StyledSpinner, StyledImg } from './SpinnerStyle';

const Spinner = () => {
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "spinner" }) {
        childImageSharp {
          fluid(quality: 90) {
            src
          }
        }
      }
    }
  `);

  return (
    <StyledSpinner>
      <StyledImg src={data.file.childImageSharp.fluid.src} alt="loader" />
    </StyledSpinner>
  );
};

export default Spinner;
